import { useState, Fragment } from "react";
import { LockIcon } from "evergreen-ui";
import { CommentForm, ListDocumentActivities } from "components/templates";
import { Button, Form, Pane, Text, Tooltip } from "components/materials";
import { get } from "lodash";
import t from "helpers/translate";
import { majorScale } from "helpers/utilities";

function DocumentActivity(props) {
  const {
    collapsedPanels,
    commentMutation: [onSubmitComment, submitCommentResult],
    document,
    handleMarkReviewed,
    markReviewedResult,
    onDirtyOrFocus,
    togglePanel,
    users,
  } = props;
  const [showCommentForm, setShowCommentForm] = useState(false);

  const activities = get(document, "documentReviewActivity", []);

  return (
    <Form.Section
      borderBottom
      heading="Activity"
      isCollapsible
      isCollapsed={collapsedPanels.includes("activity")}
      onCollapse={() => togglePanel("activity")}
      summary={`${t("documentReview.reviewedSummary", {
        count: activities.filter(({ reviewType }) => reviewType !== "comment")
          .length,
      })} / ${t("documentReview.commentsSummary", {
        count: activities.filter(({ reviewType }) => reviewType === "comment")
          .length,
      })}`}
      action={
        <Fragment>
          <Tooltip content={t("documentReview.activityTooltip")}>
            <LockIcon marginRight={majorScale(1)} color="muted" size={10} />
          </Tooltip>
          <Text color="muted" size={300} fontStyle="italic">
            Internal Only
          </Text>
        </Fragment>
      }
    >
      {!showCommentForm && (
        <Pane display="flex" marginBottom={majorScale(2)}>
          <Button
            marginRight={majorScale(1)}
            onClick={() => setShowCommentForm(true)}
            purpose="document-review comment submit"
          >
            Add Comment
          </Button>
          <Button
            isLoading={markReviewedResult.loading}
            onClick={handleMarkReviewed}
            purpose="document-review comment mark-reviewed"
          >
            Mark as Reviewed
          </Button>
        </Pane>
      )}
      <CommentForm
        mutation={onSubmitComment}
        initialValues={{ documentId: document.id, body: "" }}
        onDirtyOrFocus={onDirtyOrFocus}
        showCommentForm={showCommentForm}
        setShowCommentForm={setShowCommentForm}
        users={users}
        {...submitCommentResult}
      />
      <ListDocumentActivities activities={activities} />
    </Form.Section>
  );
}

export default DocumentActivity;
