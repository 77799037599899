import { Fragment } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import { OrganizationSelector, ReportsDrawsTable } from "components/templates";
import { Loadable, Pane } from "components/materials";
import gql from "graphql-tag";
import { omit } from "lodash";
import { PERMISSION_ACTION } from "helpers/enums";
import {
  DOCUMENT_INSPECTION_REPORT_FRAGMENT,
  REVIEW_FRAGMENT,
} from "helpers/fragments";
import { majorScale } from "helpers/utilities";
import {
  getFundingSourceAggregates,
  getCommitmentAggregates,
} from "helpers/fundingSourceHelpers";
import { dateFormToServer } from "helpers/dateHelpers";
import { getSearchByKey, removeKey } from "helpers/queryStringHelpers";
import { ScopeOrganizations } from "./ScopeOrganizations";

const QUERY = gql`
  query ReportsDrawsPageQuery($organizationId: String!, $currentDate: Date) {
    organization(id: $organizationId) {
      id
      drawAssessmentQuestions {
        id
        reportTemplateFieldName
      }
      productTypes {
        id
        type
      }
      projectRegions {
        id
        region
      }
      projectTemplates {
        id
        name
      }
      teams {
        id
        name
      }
      draws {
        id
        approvedDate
        createdAt
        createdByUser {
          id
          fullName
        }
        documentAmountPaidTotal
        documentInspectionReport {
          ...InspectionReportFragment
          vendor {
            id
            name
          }
        }
        drawInspectionReport {
          id
          date
          insertedAt
          inspectorName
          submittedAt
        }
        expectedSendDate
        fundedDate
        fundingSourceGroups {
          id
          scopeId
          amount
          disbursedAmount
          label
          organization {
            id
            name
          }
          type
        }
        recentStateUpdate {
          id
          date
        }
        inspectionReportUploadedDate
        lastDocumentAddedAt
        materialsStoredAmount
        name
        processingData(currentDate: $currentDate)
        projectId
        responses {
          id
          drawAssessmentQuestionId
          response
        }
        receivedDate
        reportDownloadedAt
        reportDownloadedBy {
          id
          fullName
        }
        requestedAmount
        retainageAmount
        reviews {
          ...ReviewFragment
        }
        reviewState
        rules {
          id
          scopeId
          enabled
          name
          state
          type
        }
        state
        submittedDate
        titleReportUploadedDate
      }
      projects {
        id
        amount
        city
        customId
        customFields {
          id
          scopeId
          label
          type
          value
        }
        expectedProjectLength
        fundingSourceGroups {
          id
          amount
          disbursedAmount
          label
          organization {
            id
            name
          }
          type
        }
        grossPercentComplete
        loanMaturityDate
        name
        organizationId
        percentComplete
        projectRegion {
          id
          region
        }
        productType {
          id
          type
        }
        requestedAmount
        stakeholders {
          id
          organization {
            id
            name
          }
          role
        }
        startDate
        state
        status
        streetAddress
        team {
          id
          name
        }
        template {
          id
          name
        }
        zip
      }
    }
  }
  ${DOCUMENT_INSPECTION_REPORT_FRAGMENT}
  ${REVIEW_FRAGMENT}
`;

function ReportsDrawsPageInner({
  onOrganizationSelected,
  selectedOrganization,
}) {
  const history = useHistory();
  const referrerOrgId = getSearchByKey(history, "referrerSelectedOrgId");

  const multiOrgNeedToChangeOrg =
    referrerOrgId && referrerOrgId !== selectedOrganization.id;

  const { data, loading } = useQuery(QUERY, {
    skip: multiOrgNeedToChangeOrg,
    variables: {
      organizationId: selectedOrganization.id,
      currentDate: dateFormToServer(Date.now()),
    },
  });

  if (multiOrgNeedToChangeOrg) {
    removeKey(history, "referrerSelectedOrgId");
    onOrganizationSelected({ id: referrerOrgId });

    return <Loadable loading />;
  }

  if (loading) return <Loadable loading />;

  const { draws, projects, drawAssessmentQuestions } = data.organization;

  const preparedDraws = prepareDrawsForTable(draws, projects);

  return (
    <ReportsDrawsTable
      drawAssessmentQuestions={drawAssessmentQuestions}
      draws={preparedDraws}
      orgData={omit(data.organization, ["draws", "projects"])}
      selectedOrganization={selectedOrganization}
    />
  );
}

export function ReportsDrawsPage() {
  return (
    <ScopeOrganizations scopeToUserPermission={PERMISSION_ACTION.RUN_REPORT}>
      {({
        onOrganizationSelected,
        disabledOrganizations,
        allOrganizations,
        selectedOrganization,
      }) => (
        <Fragment>
          <Pane margin={majorScale(2)}>
            <OrganizationSelector
              onOrganizationSelected={onOrganizationSelected}
              disabledOrganizations={disabledOrganizations}
              organizations={allOrganizations}
              selectedOrganization={selectedOrganization}
              title="Viewing draws for"
            />
          </Pane>
          <ReportsDrawsPageInner
            onOrganizationSelected={onOrganizationSelected}
            selectedOrganization={selectedOrganization}
          />
        </Fragment>
      )}
    </ScopeOrganizations>
  );
}

function prepareDrawsForTable(draws, projects) {
  const preparedProjectsLibrary = projects.reduce(
    (acc, project) => ({
      ...acc,
      [project.id]: {
        ...project,
        fundingSourceAggregates: getFundingSourceAggregates(
          project.fundingSourceGroups
        ),
        commitmentAggregates: getCommitmentAggregates(project),
      },
    }),
    {}
  );

  return draws.map((draw) => {
    const formattedResponses = draw.responses.reduce(
      (acc, { drawAssessmentQuestionId, response }) => ({
        ...acc,
        [drawAssessmentQuestionId]: response,
      }),
      {}
    );

    return {
      ...draw,
      submissionDate: draw.submittedDate || draw.expectedSendDate,
      formattedResponses,
      project: preparedProjectsLibrary[draw.projectId],
      fundingSourceAggregates: getFundingSourceAggregates(
        draw.fundingSourceGroups
      ),
      commitmentAggregates: getCommitmentAggregates({
        ...draw,
        organizationId: preparedProjectsLibrary[draw.projectId].organizationId,
      }),
    };
  });
}
