import { useContext } from "react";
import { Pane, Heading } from "components/materials";
import { ThemeContext, majorScale } from "helpers/utilities";

export function CardHeading({ disabled, text }) {
  const theme = useContext(ThemeContext);

  return (
    <Pane display="flex" alignItems="center">
      <Heading
        fontSize={majorScale(3)}
        fontWeight={500}
        lineHeight={1.2}
        color={disabled ? theme.colors.gray600 : theme.colors.headingTextGray}
      >
        {text}
      </Heading>
    </Pane>
  );
}
