import { get } from "lodash";
import { Pane, ConfigureButtons, Text } from "components/materials";
import { formatDate } from "helpers/dateHelpers";
import { toBase64 } from "components/materials/FastDataTable";
import { majorScale, minorScale } from "helpers/utilities";
import { BlankSlate, BlankSlateType } from "./BlankSlate";
import { CardHeading } from "./CardHeading";
import { LinkCell, Table } from "./Table";
import { CardLinkButton } from "./CardLinkButton";

export const FUNDED_DRAWS_CONFIGURATION_SETTINGS = {
  i: "fundedDraws",
  x: 1,
  y: 3,
  w: 1,
  h: 1,
  disabled: false,
};

export function FundedDraws({
  cards,
  isConfigurable,
  isDisabled,
  name,
  projects,
  setCards,
}) {
  const tableData = projects
    .filter(
      (project) =>
        project.lastDrawFunded && get(project, "lastDrawFunded.daysSinceFunded")
    )
    .map(({ name, lastDrawFunded, id }) => {
      const fundedDate = get(lastDrawFunded, "fundedDate", null);
      const lastDrawId = get(lastDrawFunded, "id", null);

      return {
        projectName: name,
        lastDrawName: get(lastDrawFunded, "name", null),
        daysSinceFunded: get(lastDrawFunded, "daysSinceFunded", null),
        formattedFundedDate: formatDate(fundedDate, "-", "MM/dd/yyyy"),
        projectUrl: `/projects/${id}`,
        drawUrl: `/projects/${id}/draws/${lastDrawId}`,
      };
    });

  const columns = [
    {
      name: "Project",
      renderCell: (row) => (
        <LinkCell
          name={row.projectName}
          url={row.projectUrl}
          characterLimit={24}
        />
      ),
      sortKey: "projectName",
      width: "40%",
    },
    {
      name: "Last Draw",
      renderCell: (row) => {
        return row.lastDrawName ? (
          <LinkCell
            name={row.lastDrawName}
            url={row.drawUrl}
            characterLimit={20}
          />
        ) : (
          <Text>-</Text>
        );
      },
      sortKey: "lastDrawName",
      width: "30%",
    },
    {
      name: "Date Funded",
      renderCell: (row) => <Text>{row.formattedFundedDate}</Text>,
      sortKey: "formattedFundedDate",
      width: "15%",
    },
    {
      name: "Elapsed",
      renderCell: (row) => (
        <Text>{row.daysSinceFunded ? `${row.daysSinceFunded} days` : "-"}</Text>
      ),
      sortKey: "daysSinceFunded",
      width: "15%",
    },
  ];

  const showBlankSlate = projects.length === 0;

  const fundedDrawsViewConfig = toBase64({
    columnConfig: [
      "projectName",
      "projectTotal",
      "commitments",
      "currentDraw",
      "percentRemaining",
      "lastDrawFunded",
    ],
    filterConfig: [],
    groupConfig: {},
    sortConfig: {},
  });

  return (
    <Pane height="100%" width="100%" padding={majorScale(2)}>
      <Pane
        display="flex"
        marginBottom={minorScale(3)}
        justifyContent="space-between"
        paddingBottom={majorScale(2)}
      >
        <CardHeading disabled={isDisabled} text="Funded Draws" />
        {isConfigurable && (
          <ConfigureButtons
            isDisabled={isDisabled}
            cards={cards}
            setCards={setCards}
            name={name}
          />
        )}
        {!isConfigurable && (
          <CardLinkButton
            href={`/reports/projects?table=${fundedDrawsViewConfig}`}
          />
        )}
      </Pane>
      <Pane>
        <Pane maxHeight={350} overflowY="scroll">
          <Pane>
            <Table
              columns={columns}
              defaultSortDirection="desc"
              defaultSortKey="daysSinceFunded"
              rows={tableData}
              cellBorderY={false}
              hasFooter={false}
            />
          </Pane>
        </Pane>
      </Pane>
      {showBlankSlate && <BlankSlate type={BlankSlateType.Draws} />}
    </Pane>
  );
}
