import { useContext } from "react";
import { ArrowRightIcon } from "evergreen-ui";
import { useHistory } from "react-router-dom";
import { hover } from "glamor";
import { max, uniq } from "lodash";
import { ConfigureButtons, Pane, Text, Shortener } from "components/materials";
import { add } from "helpers/math";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { BlankSlate } from "./BlankSlate";
import {
  getSerializedURLForProjectReport,
  trackPortfolioAnalysis,
} from "./helpers";
import { toBase64 } from "../../materials/FastDataTable";
import { CardHeading } from "./CardHeading";
import { CardLinkButton } from "./CardLinkButton";

export const PROJECTS_BY_TEAM_CONFIGURATION_SETTINGS = {
  i: "projectsByTeam",
  x: 1,
  y: 6,
  w: 1,
  h: 1,
  disabled: false,
};

function TeamCount({
  navigateToProjects,
  activeProjectCount,
  maxNumProjects,
  theme,
  name,
}) {
  return (
    <Pane display="flex" marginBottom={majorScale(2)}>
      <Pane
        display="flex"
        alignItems="center"
        cursor="pointer"
        onClick={navigateToProjects}
        width={300}
        marginRight={majorScale(2)}
      >
        <Shortener
          color={theme.colors.linkBlue}
          className={hover({
            textDecoration: "underline",
          })}
          limit={20}
          size={200}
          text={name}
        />
        <ArrowRightIcon
          color={theme.colors.linkBlue}
          icon={ArrowRightIcon}
          appearance="minimal"
          marginLeft="auto"
        />
      </Pane>
      <Pane width="100%">
        <Pane
          paddingX={minorScale(2)}
          display="flex"
          alignItems="center"
          width={`${max([6, (activeProjectCount / maxNumProjects) * 100])}%`}
          height={30}
          background={theme.colors.barChartBlue}
        >
          <Pane marginLeft="auto">
            <Text color="white">{activeProjectCount}</Text>
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  );
}

export function ProjectsByTeam({
  isConfigurable,
  isDisabled,
  cards,
  setCards,
  name,
  teams,
  projects,
  organization,
}) {
  const history = useHistory();
  const theme = useContext(ThemeContext);

  const organizationId = organization.id;

  const countForTeam = teams.reduce(
    (acc, team) => ({
      ...acc,
      [team.name]: acc[team.name] ? add(acc[team.name], 1) : 1,
    }),
    {}
  );

  const uniqueTeams = uniq(teams);
  const sortedTeams = [...uniqueTeams].sort(
    (teamA, teamB) => countForTeam[teamB.name] - countForTeam[teamA.name]
  );

  const assignedTotal = Object.keys(countForTeam).reduce(
    (acc, key) => acc + countForTeam[key],
    0
  );

  const noTeamAssignedTotal = projects.length - assignedTotal;

  const maxNumProjects = Math.max(
    countForTeam[sortedTeams[0]?.name],
    noTeamAssignedTotal
  );

  const showBlankSlate = projects.length === 0;

  const groupedByTeamConfig = toBase64({
    columnConfig: [
      "projectName",
      "projectTotal",
      "equityCommitted",
      "debtCommitted",
      "amountRemaining",
      "percentComplete",
    ],
    filterConfig: [
      {
        enum: ["Active", "Pre-Development", "Under Contract"],
        input: ["Active", "Pre-Development", "Under Contract"],
        key: "projectStatus",
        operator: "EXACT",
      },
    ],
    groupConfig: { columnId: "team" },
    sortConfig: {},
  });

  return (
    <Pane height="100%" width="100%" padding={majorScale(2)}>
      <Pane
        display="flex"
        marginBottom={minorScale(3)}
        justifyContent="space-between"
        paddingBottom={majorScale(2)}
      >
        <CardHeading disabled={isDisabled} text="Projects By Team" />
        {isConfigurable && (
          <ConfigureButtons
            isDisabled={isDisabled}
            cards={cards}
            setCards={setCards}
            name={name}
          />
        )}
        {!isConfigurable && (
          <CardLinkButton
            href={`/reports/projects?table=${groupedByTeamConfig}`}
          />
        )}
      </Pane>
      {showBlankSlate ? (
        <BlankSlate />
      ) : (
        <Pane height="85%" paddingTop={majorScale(2)}>
          <Pane height="100%" overflowY="scroll">
            {sortedTeams.map((team) => {
              const { name: teamName } = team;
              const navigateToProjects = () => {
                trackPortfolioAnalysis(
                  organizationId,
                  "Go to Projects (filtered by team)"
                );
                return history.push(
                  getSerializedURLForProjectReport(
                    "team",
                    {
                      enum: [teamName],
                    },
                    organizationId
                  )
                );
              };

              return (
                <TeamCount
                  key={team.id}
                  name={team.name}
                  theme={theme}
                  maxNumProjects={maxNumProjects}
                  navigateToProjects={navigateToProjects}
                  activeProjectCount={countForTeam[team.name]}
                />
              );
            })}
            {noTeamAssignedTotal !== 0 && (
              <TeamCount
                navigateToProjects={() => {
                  trackPortfolioAnalysis(
                    organizationId,
                    "Go to Projects (filtered by team)"
                  );
                  return history.push(
                    getSerializedURLForProjectReport("team", {
                      enum: ["-"],
                    })
                  );
                }}
                maxNumProjects={maxNumProjects}
                name="Unassigned"
                theme={theme}
                activeProjectCount={noTeamAssignedTotal}
              />
            )}
          </Pane>
        </Pane>
      )}
    </Pane>
  );
}
