import { RULE_STATE, REVIEW_STATE, PERMISSION_ACTION } from "helpers/enums";
import isBlank from "helpers/isBlank";
import { sumBy } from "helpers/math";
import {
  getFullyApprovedDocumentsCounts,
  getCompletedDocumentsCounts,
  prepareDocuments,
} from "../../DrawOverviewPage/Documents";

export const showDocumentApprovalStatus = (hasPermission, project) => {
  return (
    hasPermission(PERMISSION_ACTION.TIERED_DOCUMENT_REVIEWERS) &&
    project.documentReviewers.length > 0
  );
};

export const showInspection = (hasPermission) =>
  hasPermission(PERMISSION_ACTION.INSPECTION_REPORT_WORKFLOW);

export const showPaymentsTab = (hasPermission) =>
  hasPermission(PERMISSION_ACTION.AVID_AP_INTEGRATION) ||
  hasPermission(PERMISSION_ACTION.YARDI_INTEGRATION) ||
  hasPermission(PERMISSION_ACTION.NEXUS_INTEGRATION) ||
  hasPermission(PERMISSION_ACTION.PUSH_TO_MRI) ||
  hasPermission(PERMISSION_ACTION.PUSH_TO_QUICK_BOOKS_ONLINE) ||
  hasPermission(PERMISSION_ACTION.VISION_DELTEK_AP_INTEGRATION) ||
  hasPermission(PERMISSION_ACTION.PUSH_TO_BILL);

export function getDocumentsRatio({ draw, project }) {
  const preparedDocumentGroups = prepareDocuments(
    draw.drawOverviewDocuments,
    project.documentReviewers
  );

  return getCompletedDocumentsCounts(preparedDocumentGroups);
}

export function getDocumentApprovalsRatio({ draw, project }) {
  const preparedDocumentGroups = prepareDocuments(
    draw.drawOverviewDocuments,
    project.documentReviewers
  );

  return getFullyApprovedDocumentsCounts(preparedDocumentGroups);
}

export function getBudgetRatio({ draw }) {
  const { lineItems } = draw;

  const requestedLineItems = lineItems.filter(
    ({ requestedAmount }) => requestedAmount !== 0
  );
  const requestedItemsWithBalanceRemaining = requestedLineItems.filter(
    ({ balanceToFundAmount }) => balanceToFundAmount >= 0
  );

  return {
    numerator: requestedLineItems.length,
    denominator: requestedItemsWithBalanceRemaining.length,
  };
}

export function getRulesRatio({ draw }) {
  const { rules } = draw;
  const relevantRules = rules.filter(
    ({ enabled, state }) => enabled && state !== RULE_STATE.IGNORED
  );
  const passedRules = relevantRules.filter(
    ({ state }) => state === RULE_STATE.PASSED
  );

  return {
    numerator: passedRules.length,
    denominator: relevantRules.length,
  };
}

export function getFundingSourcesRatio({ draw }) {
  const { requestedAmount, fundingSources } = draw;
  const disbursedTotal = sumBy(fundingSources, "disbursedAmount");

  return {
    numerator: disbursedTotal,
    denominator: requestedAmount,
  };
}

export function getInspectionRatio({ draw }) {
  return {
    numerator: draw?.drawInspectionReport?.status === "SUBMITTED" ? 1 : 0,
    denominator: 1,
  };
}

export function getDrawAssessmentsRatio({ draw }) {
  const { questions, responses } = draw;
  const answeredQuestions = questions.filter(
    (question) =>
      !!responses.find(
        ({ drawAssessmentQuestionId, response }) =>
          drawAssessmentQuestionId === question.id && !isBlank(response)
      )
  );

  return {
    numerator: answeredQuestions.length,
    denominator: questions.length,
  };
}

export function getDrawApprovalsRatio({ draw }) {
  const { reviews } = draw;
  const approvedReviews = reviews.filter(
    ({ state }) => state === REVIEW_STATE.APPROVED
  );

  return {
    numerator: approvedReviews.length,
    denominator: reviews.length,
  };
}

export function getPaymentsRatio({ draw }) {
  const { payableDocuments } = draw;

  return {
    numerator: payableDocuments.filter(({ isPaid }) => isPaid).length,
    denominator: payableDocuments.length,
  };
}

export function getRatioColor(numerator, denominator) {
  if (numerator < denominator) return "yellow";
  if (numerator > denominator) return "red";
  return "green";
}
