import {
  AGREEMENT_TYPE,
  DOCUMENT_TYPE_NAME,
  DOCUMENT_STATE,
  PERMISSION_ACTION,
} from "helpers/enums";
import {
  clone,
  filter,
  get,
  includes,
  max,
  minBy,
  some,
  uniqBy,
  values,
} from "lodash";
import { stringComparator } from "helpers/comparators";
import { formatCurrency } from "helpers/formatCurrency";
import { compareDesc } from "helpers/dateHelpers";
import t from "helpers/translate";
import isBlank from "helpers/isBlank";

export const PAYABLE_DOCUMENT_TYPE_NAMES = [
  DOCUMENT_TYPE_NAME.INVOICE,
  DOCUMENT_TYPE_NAME.PAY_APPLICATION,
];

const DOCUMENTATION_DOCUMENT_TYPE_NAMES = [
  DOCUMENT_TYPE_NAME.INVOICE,
  DOCUMENT_TYPE_NAME.PAY_APPLICATION,
  DOCUMENT_TYPE_NAME.CONDITIONAL_LIEN_RELEASE,
  DOCUMENT_TYPE_NAME.UNCONDITIONAL_LIEN_RELEASE,
];

export const PAYMENT_OPTIONS = {
  POSTED: "Posted",
  NOT_POSTED: "Not Posted",
  NOT_POSTABLE: "Not Postable",
};

export function isAgreementDocument(document) {
  return !!AGREEMENT_TYPE[document.type];
}

export function isApprovableDocumentType(document) {
  return values(AGREEMENT_TYPE)
    .concat([DOCUMENT_TYPE_NAME.INVOICE, DOCUMENT_TYPE_NAME.PAY_APPLICATION])
    .includes(document.type);
}

export function isPayableDocument(document) {
  return (
    PAYABLE_DOCUMENT_TYPE_NAMES.includes(document.type) &&
    isApplied(document) &&
    document.draw &&
    document.vendor.id
  );
}

export function isUploaded(document) {
  return document.state === DOCUMENT_STATE.ADDED && document.type === null;
}
export function isClassified(document) {
  return document.state === DOCUMENT_STATE.ADDED && document.type !== null;
}
export function isApplied({ state }) {
  return state === DOCUMENT_STATE.APPLIED;
}
export function isParsed({ state }) {
  return state === DOCUMENT_STATE.PARSED || state === DOCUMENT_STATE.CORRECTED;
}

export function isFailed({ state }) {
  return state === DOCUMENT_STATE.FAILED;
}
export function hasErrors({ errors }) {
  return errors.length > 0;
}

export function removeDismissedWarnings(warnings, dismissedWarnings) {
  return Object.keys(warnings).reduce((acc, warning) => {
    if (!dismissedWarnings.includes(warnings[warning].type))
      acc[warning] = warnings[warning];
    return acc;
  }, {});
}

export function isIgnored(document) {
  return get(document, "state") === DOCUMENT_STATE.IGNORED;
}

export function isDocumentGroup({ __typename }) {
  return __typename === "DocumentGroup";
}

export function canIgnore(document) {
  return (
    !isDocumentGroup(document) && document.state !== DOCUMENT_STATE.IGNORED
  );
}

export function documentIsProcessing(document) {
  return (
    get(document, "state") === DOCUMENT_STATE.ADDED &&
    get(document, "processingFinishedAt") === null
  );
}

export function documentIsTimedOut(document) {
  return (
    get(document, "errors") &&
    some(document.errors, ({ message }) => message === "processing_timeout")
  );
}

export function uploadIsClassifying(upload) {
  return (
    get(upload, "documents") && some(upload.documents, ({ type }) => !type)
  );
}

export function uploadIsProcessing(upload) {
  return (
    get(upload, "processingFinishedAt") === null ||
    (get(upload, "documents") &&
      some(upload.documents, (document) => documentIsProcessing(document)))
  );
}

// This checks a few conditions for gating approvability of a document:
// that the appropriate permissions are enabled,
// that the user is able to approve documents according to the project's configuration of document reviewers, if applicable,
// that the document is of an approveable type,
// and that the document's range falls within the user's approval $ range, if applicable
// This does NOT consider current approvals on the document.
export function userCanApproveDocument(
  document,
  user,
  documentReviewers,
  hasPermission
) {
  if (!hasPermission(PERMISSION_ACTION.TIERED_DOCUMENT_REVIEWERS)) return false;

  if (!userIsDocumentApprover(documentReviewers, user)) return false;

  if (!isApprovableDocumentType(document)) return false;
  return userCanApproveDocumentAmount(document, user);
}

function userIsDocumentApprover(documentReviewers, user) {
  // if there are no documentReviewers configured, any user can approve documents
  if (documentReviewers.length === 0) return true;
  return documentReviewers.find((reviewer) => reviewer.user.id === user.id);
}

export function userCanApproveDocumentAmount(document, user) {
  // only Executed Change Orders can be associated with an adjustment
  // the adjusted value is the agreement value used for comparison against a user's approval threshold
  if (
    isAgreementDocument(document) &&
    document.type !== DOCUMENT_TYPE_NAME.EXECUTED_CHANGE_ORDER
  )
    return true;
  if (user.approvalAmountMinimum === null && user.approvalAmountLimit === null)
    return true;

  const amount = amountForApproval(document);
  if (
    user.approvalAmountMinimum !== null &&
    user.approvalAmountMinimum > amount
  )
    return false;
  if (user.approvalAmountLimit !== null && user.approvalAmountLimit < amount)
    return false;

  return true;
}

const amountForApproval = (document) =>
  document.type === DOCUMENT_TYPE_NAME.EXECUTED_CHANGE_ORDER
    ? document.adjustedAmount
    : document.amount;

export function getMostRecentDocumentReview(reviews) {
  const sortedReviews = clone(reviews).sort((reviewA, reviewB) => {
    return compareDesc(reviewA.insertedAt, reviewB.insertedAt);
  });

  return sortedReviews.length > 0 ? sortedReviews[0] : null;
}

export function getMostRecentDocumentApproval(reviews) {
  const approvedReviews = reviews.filter(
    ({ reviewType }) => reviewType === "approved"
  );

  return getMostRecentDocumentReview(approvedReviews);
}

export function getLastApproval(reviews, documentReviewers) {
  if (documentReviewers.length > 0)
    return getMostRecentDocumentApproval(reviews);
  const recentReview = getMostRecentDocumentReview(reviews);
  return recentReview?.reviewType === "approved" ? recentReview : null;
}

export function aggregateDocumentApprovalsData(document, documentReviewers) {
  if (documentReviewers.length === 0)
    return getUnorderedApprovalData(document.recentApprovalReviews);
  return getOrderedApprovalData(document, documentReviewers);
}

function getUnorderedApprovalData(reviews) {
  // in an unordered workflow, the most recent review determines a document's approval status
  const recentReview = getMostRecentDocumentReview(reviews);
  const documentIsApproved =
    recentReview && recentReview.reviewType === "approved";
  const approvedReviews = documentIsApproved ? [recentReview] : [];
  return {
    approvedCount: approvedReviews.length,
    reviewersCount: 1,
    approvedReviews,
    pendingReviewers: [],
  };
}

function getOrderedApprovalData(document, documentReviewers) {
  const relevantReviewers = documentReviewers.filter(({ user }) =>
    userCanApproveDocumentAmount(document, user)
  );

  const approvedReviews = document.recentApprovalReviews.filter(
    ({ reviewType, userId }) =>
      reviewType === "approved" &&
      // check that the approving user is still relevant to the document
      // (maybe they have since been removed as reviewer, maybe their approval limits have changed)
      !!relevantReviewers.find((reviewer) => reviewer.user.id === userId)
  );

  const pendingReviewers = relevantReviewers.filter(
    ({ user }) => !approvedReviews.find((review) => review.userId === user.id)
  );

  return {
    approvedCount: approvedReviews.length,
    reviewersCount: relevantReviewers.length,
    approvedReviews,
    pendingReviewers,
  };
}

export function getNextOrderedReviewer(document, documentReviewers) {
  if (documentReviewers.length === 0) return null;
  const { approvedReviews, pendingReviewers } = aggregateDocumentApprovalsData(
    document,
    documentReviewers
  );

  const approvedPositions = approvedReviews.map(({ userId }) => {
    return documentReviewers.find(({ user }) => user.id === userId)?.position;
  });

  const latestApprovedPosition = max(approvedPositions);

  const laterPendingReviewers =
    latestApprovedPosition === undefined
      ? pendingReviewers
      : pendingReviewers.filter(
          ({ position }) => position > latestApprovedPosition
        );

  return minBy(laterPendingReviewers, "position");
}

export function getDocumentApprovalStatus(document, user, documentReviewers) {
  const { approvedBy, approvedUsers } = document;
  const hasDocumentReviewersConfigured = documentReviewers.length > 0;
  const documentIsApprovedForUnorderedWorkflow = !!approvedBy;
  const userHasApprovedInOrderedWorkflow = !!approvedUsers.find(
    ({ id }) => id === user.id
  );

  return {
    hasDocumentReviewersConfigured,
    documentIsApprovedForUnorderedWorkflow,
    userHasApprovedInOrderedWorkflow,
    documentHasApprovedStatusForUser: hasDocumentReviewersConfigured
      ? userHasApprovedInOrderedWorkflow
      : documentIsApprovedForUnorderedWorkflow,
  };
}

export function isSummaryWithAdjustments(document) {
  return (
    get(document, "type") === DOCUMENT_TYPE_NAME.DRAW_SUMMARY &&
    get(document, "hasAdjustments")
  );
}

export function isAgreementWithAdjustments(document) {
  return (
    get(document, "type") === DOCUMENT_TYPE_NAME.EXECUTED_CHANGE_ORDER &&
    (get(document, "hasAdjustments") ||
      get(document, "agreement.budgetAdjustment"))
  );
}

export function getDocumentTypeOptions(hasPermission, rejectTypes = []) {
  const excludeDocumentTypes = rejectTypes.concat(
    excludeDocumentTypesByPermissions(hasPermission)
  );

  return filter(
    DOCUMENT_TYPE_NAME,
    (name) => excludeDocumentTypes.indexOf(name) === -1
  )
    .map((name) => ({
      key: name,
      text: t(`documentTypeName.${name}`),
      value: name,
    }))
    .sort((a, b) => stringComparator(a.text, b.text));
}

export function getDocumentTypes(hasPermission) {
  return getDocumentTypeOptions(hasPermission).map((option) => option.text);
}

export function getLineItemsWithInsufficientFunds(issues, lineItems) {
  const insufficientFundsIssues = issues.filter(
    ({ lineItemId, name }) =>
      lineItemId && name === "line_item_has_negative_balance_to_fund"
  );

  return uniqBy(
    lineItems.filter(({ lineItemObject }) =>
      insufficientFundsIssues.find(
        ({ lineItemId }) => lineItemObject.id === lineItemId
      )
    ),
    "lineItemObject.id"
  );
}

export function formatFastDocuments(documents, projects = []) {
  const projectsLibrary = projects.reduce(
    (acc, project) => ({
      ...acc,
      [project.id]: project,
    }),
    {}
  );
  return documents.map((document) => {
    const project = projectsLibrary[document.projectId];
    return {
      ...document,
      ...(document.draw ? { draw: { name: document.draw } } : {}),
      file: { name: document.fileName },
      project: {
        customId: document.projectCustomId,
        id: document.projectId,
        name: document.projectName,
      },
      vendor: {
        id: document.vendorName,
        name: document.vendorName,
        vendorCostCode: document.vendorCostCode,
      },
      originalFile: { name: document.originalFileName },
      assignedUser: { fullName: document.assignedUser },
      jobCostCodes: document.jobCostCodes.map((code) => ({ code })),
      errors: document.errors.map((error) => ({ message: error })),
      lineItems: document.lineItems.map((lineItem) =>
        document.type === DOCUMENT_TYPE_NAME.PAY_APPLICATION
          ? { descriptionOfWork: lineItem }
          : { name: lineItem }
      ),
      divisionNames: document.divisionNames,
      // fields below only used on the Documents Report Page
      ...(project
        ? {
            productType: project.productType?.type,
            projectRegion: project.projectRegion?.region,
            projectStatus: t(`projectStatus.${project.status}`),
            projectType: project.template.name,
            team: project.team?.name,
          }
        : {}),
    };
  });
}

export function getCalculatedFileName(document) {
  const { type, vendorName, originalFileName, agreementName } = document;
  const hasVendor = !!vendorName;

  const extension = getFileExtension(originalFileName);
  const fileNameWithoutExtension = extension
    ? originalFileName.slice(0, -(extension.length + 1))
    : originalFileName;

  if (type in AGREEMENT_TYPE) {
    if (!!agreementName && !!extension) return `${agreementName}.${extension}`;
    if (agreementName) return agreementName;
    const normalizedFileName = normalizeFileName(fileNameWithoutExtension);
    return addExtension(normalizedFileName, extension);
  }

  const prefix = hasVendor
    ? getPrefixWithVendor(document)
    : getPrefixWithPages(fileNameWithoutExtension, document);

  const normalizedFileName = normalizeFileName(prefix);
  return addExtension(normalizedFileName, extension);
}

// This can expand as we add document types controlled by feature flags
function excludeDocumentTypesByPermissions(hasPermission) {
  const excludeDocumentTypes = [];
  if (!hasPermission(PERMISSION_ACTION.HUD_FEATURES)) {
    excludeDocumentTypes.push(DOCUMENT_TYPE_NAME.HUD_5372);
    excludeDocumentTypes.push(DOCUMENT_TYPE_NAME.HUD_92403_PAGE_1);
    excludeDocumentTypes.push(DOCUMENT_TYPE_NAME.HUD_92403_PAGE_2);
    excludeDocumentTypes.push(DOCUMENT_TYPE_NAME.HUD_2448);
    excludeDocumentTypes.push(DOCUMENT_TYPE_NAME.HUD_92464);
  }
  return excludeDocumentTypes;
}

function getPrefixWithVendor(document) {
  const { amount, type, vendorName } = document;
  const documentationAmount = includes(DOCUMENTATION_DOCUMENT_TYPE_NAMES, type)
    ? formatCurrency(amount)
    : null;
  return [vendorName, t(`documentTypeName.${type}`), documentationAmount]
    .filter((data) => !!data)
    .join("_");
}

function getPrefixWithPages(fileNameWithoutExtension, document) {
  const { fileContentType, pages } = document;

  if (fileContentType !== "application/pdf" || pages.length === 0)
    return fileNameWithoutExtension;

  if (pages.length === 1) return `${fileNameWithoutExtension}_page_${pages[0]}`;

  const sortedPages = clone(pages).sort((pageA, pageB) => pageA - pageB);
  const firstPage = sortedPages[0];
  const lastPage = sortedPages[sortedPages.length - 1];
  return `${fileNameWithoutExtension}_pages_${firstPage}-${lastPage}`;
}

function getFileExtension(fileName) {
  if (isBlank(fileName)) return "";
  const foundExtension = fileName.split(".").pop();
  if (foundExtension === fileName) return "";
  return foundExtension;
}

function normalizeFileName(fileName) {
  if (isBlank(fileName)) return "";
  return (
    fileName
      .trim()
      // this regex has been applied to the calculated fielname since it's creation
      // I believe it is intended to remove characters that could cause an issue when printing the filename
      // but I am not sure
      // eslint-disable-next-line
      .replace(/[\x00-\x1F\/\\:\*\?<>\|]/u, "")
  );
}

function addExtension(fileName, extension) {
  if (isBlank(extension)) return fileName;
  return `${fileName}.${extension}`;
}
