import { Fragment } from "react";
import { ErrorIcon } from "evergreen-ui";
import { Dimmer, Pane, Segment, Tooltip } from "components/materials";
import {
  FastDataTable,
  COMPARATORS,
  toBase64,
  currencyColumnDefaults,
  numberColumnDefaults,
  percentColumnDefaults,
  primaryColumnDefaults,
  stringColumnDefaults,
} from "components/materials/FastDataTable";
import { majorScale } from "helpers/utilities";
import {
  NET_ZERO,
  amountOrNetZero,
  getLineItemPosition,
  renderCommentIcon,
  renderRetainageIcon,
} from "helpers/lineItemTableHelpers";
import { getPercentAggregate } from "helpers/tableAggregateHelpers";
import { failedRules, hasFailedRules } from "helpers/ruleHelpers";
import { formatCurrency } from "helpers/formatCurrency";
import { divide, subtract, sumBy } from "helpers/math";

const serialized = toBase64({
  columnConfig: null,
  filterConfig: [
    {
      input: "$0.00",
      key: "currentAmountRequested",
      operator: COMPARATORS.GREATER_THAN.value,
    },
  ],
  groupConfig: { columnId: "division" },
  sortConfig: { columnId: "position", direction: "asc" },
});

const columns = [
  {
    ...stringColumnDefaults,
    ...primaryColumnDefaults,
    header: "Line Item",
    id: "lineItem",
    value: (lineItem) => lineItem.name,
    valueFormatter: (value, lineItem) => (
      <Fragment>
        {value}
        {hasFailedRules(lineItem) && (
          <Tooltip content={failedRules(lineItem)}>
            <ErrorIcon color="danger" size={10} marginLeft={majorScale(1)} />
          </Tooltip>
        )}
        {renderCommentIcon(lineItem)}
        {renderRetainageIcon(lineItem)}
      </Fragment>
    ),
    width: 300,
  },
  {
    ...stringColumnDefaults,
    groupable: true,
    header: "Division",
    id: "division",
    value: (lineItem) => lineItem.division.name,
    width: 200,
  },
  {
    ...numberColumnDefaults,
    header: "Position",
    hidden: true,
    id: "position",
    value: getLineItemPosition,
  },
  {
    ...currencyColumnDefaults,
    aggregate: (lineItems) => sumBy(lineItems, "originalBudgetAmount"),
    header: "Original Budget",
    id: "originalBudget",
    value: (lineItem) => lineItem.originalBudgetAmount,
  },
  {
    ...currencyColumnDefaults,
    aggregate: (lineItems) =>
      amountOrNetZero(lineItems, "previousAdjustmentsAmount"),
    aggregateFormatter: (value) =>
      value === NET_ZERO ? "Net 0" : formatCurrency(value),
    header: "Previous Adjustments",
    id: "previousAdjustments",
    value: (lineItem) => lineItem.previousAdjustmentsAmount,
  },
  {
    ...currencyColumnDefaults,
    aggregate: (lineItems) => amountOrNetZero(lineItems, "adjustmentsAmount"),
    aggregateFormatter: (value) =>
      value === NET_ZERO ? "Net 0" : formatCurrency(value),
    header: "Current Adjustments",
    id: "currentAdjustments",
    value: (lineItem) => lineItem.adjustmentsAmount,
  },
  {
    ...currencyColumnDefaults,
    aggregate: (lineItems) => sumBy(lineItems, "budgetAmount"),
    header: "Current Budget",
    id: "currentBudget",
    value: (lineItem) => lineItem.budgetAmount,
  },
  {
    ...currencyColumnDefaults,
    aggregate: (lineItems) => sumBy(lineItems, "requestedPreviouslyAmount"),
    header: "Previous Draws (Net)",
    id: "previousDraws",
    value: (lineItem) => lineItem.requestedPreviouslyAmount,
  },
  {
    ...currencyColumnDefaults,
    aggregate: (lineItems) =>
      sumBy(lineItems, "grossRequestedPreviouslyAmount"),
    header: "Previous Draws (Gross)",
    id: "grossPreviousDraws",
    value: (lineItem) => lineItem.grossRequestedPreviouslyAmount,
  },
  {
    ...currencyColumnDefaults,
    aggregate: (lineItems) => sumBy(lineItems, "requestedAmount"),
    header: "Current Amount Requested (Net)",
    id: "currentAmountRequested",
    value: (lineItem) => lineItem.requestedAmount,
  },
  {
    ...currencyColumnDefaults,
    aggregate: (lineItems) => sumBy(lineItems, "grossRequestedAmount"),
    header: "Current Amount Requested (Gross)",
    id: "grossCurrentAmountRequested",
    value: (lineItem) => lineItem.grossRequestedAmount,
  },
  {
    ...currencyColumnDefaults,
    aggregate: (lineItems) => sumBy(lineItems, "retainageAmount"),
    header: "Retainage Amount",
    id: "retainageAmount",
    tooltip: "Retainage withheld this draw",
    value: (lineItem) => lineItem.retainageAmount,
  },
  {
    ...currencyColumnDefaults,
    aggregate: (lineItems) => sumBy(lineItems, "balanceToFundAmount"),
    header: "Balance To Fund Amount",
    id: "balanceToFundAmount",
    value: (lineItem) => lineItem.balanceToFundAmount,
  },
  {
    ...percentColumnDefaults,
    aggregate: (lineItems) =>
      getPercentAggregate(lineItems, "percentRemaining", "budgetAmount"),
    header: "% Remaining (Net)",
    id: "percentRemaining",
    value: (lineItem) =>
      lineItem.types?.includes("CONTINGENCY")
        ? divide(lineItem.balanceToFundAmount, lineItem.originalBudgetAmount)
        : lineItem.percentRemaining,
  },
  {
    ...percentColumnDefaults,
    aggregate: (lineItems) =>
      getPercentAggregate(lineItems, "grossPercentRemaining", "budgetAmount"),
    header: "% Remaining (Gross)",
    id: "grossPercentRemaining",
    value: (lineItem) =>
      lineItem.types?.includes("CONTINGENCY")
        ? divide(
            subtract(
              lineItem.balanceToFundAmount,
              lineItem.retainageToDateAmount
            ),
            lineItem.originalBudgetAmount
          )
        : lineItem.grossPercentRemaining,
  },
];

export function SubmissionPreviewTable({ actionContent, draw }) {
  return (
    <Dimmer.Dimmable as={Segment}>
      <Dimmer darker active>
        <Segment rounded attached>
          {actionContent}
        </Segment>
      </Dimmer>
      <Pane height="95vh">
        <FastDataTable
          columns={columns}
          items={draw.lineItems}
          pageSize={null}
          serialized={serialized}
          footerTotals
        />
      </Pane>
    </Dimmer.Dimmable>
  );
}
