import { useContext } from "react";
import { InfoSignIcon } from "evergreen-ui";
import { get } from "lodash";
import { Pane, Text } from "components/materials";
import { AddProjectButton } from "components/templates";
import t from "helpers/translate";
import { majorScale, ThemeContext } from "helpers/utilities";

export enum BlankSlateType {
  Contingency = "contingency",
  Draws = "draws",
  ProjectCosts = "projectCosts",
  ScheduleRisk = "scheduleRisk",
  Standard = "standard",
  Tasks = "tasks",
}

const RABBET_OBJECT_BY_SLATE_TYPE = {
  [BlankSlateType.Draws]: "Draws",
  [BlankSlateType.Tasks]: "Tasks",
};

export function BlankSlate({ type = BlankSlateType.Standard }) {
  const theme = useContext(ThemeContext) as any;

  const renderCTA = () => {
    switch (type) {
      case BlankSlateType.Draws:
      case BlankSlateType.Tasks:
        return null;
      default:
        return <AddProjectButton />;
    }
  };

  const descriptorText = `No ${get(
    RABBET_OBJECT_BY_SLATE_TYPE,
    type,
    "Projects"
  )} Found`;

  return (
    <Pane
      alignItems="center"
      display="flex"
      flexDirection="column"
      height={300}
      justifyContent="center"
      rowGap={majorScale(1)}
    >
      <Pane
        style={{
          borderRadius: "50%",
          height: "72px",
          // Midpoint between neutralBlueMessageBorder and white to simulate a thinner border
          outline: "2px solid #E1E8FF",
          position: "relative",
          width: "72px",
        }}
      >
        <Pane
          style={{
            borderRadius: "50%",
            height: "48px",
            left: "12px",
            outline: `2px solid ${theme.alerts.neutralBlueMessageBorder}`,
            position: "absolute",
            top: "12px",
            width: "48px",
          }}
        >
          <InfoSignIcon
            color={theme.alerts.neutralBlueMessageText}
            left="16px"
            position="absolute"
            size={16}
            top="16px"
          />
        </Pane>
      </Pane>
      <Text fontSize={16} fontWeight={700} textAlign="center">
        {descriptorText}
      </Text>
      <Text fontSize={16} textAlign="center">
        {t(`portfolioInsights.blankSlate.${type}`)}
      </Text>
      {renderCTA()}
    </Pane>
  );
}
