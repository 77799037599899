import { Pane, ConfigureButtons } from "components/materials";
import { majorScale, minorScale } from "helpers/utilities";
import { BlankSlate, BlankSlateType } from "./BlankSlate";
import { CardHeading } from "./CardHeading";
import { CardLinkButton } from "./CardLinkButton";

export const CONTINGENCY_RISK_CONFIGURATION_SETTINGS = {
  i: "contingencyRisk",
  x: 0,
  y: 1,
  w: 1,
  h: 1.5,
  disabled: false,
};

export function ContingencyRisk({
  cards,
  isConfigurable,
  isDisabled,
  name,
  projects,
  setCards,
}) {
  // Will be updated to show blank slate if no projects with contingency are present, once implemented
  const showBlankSlate = projects.length === 0;

  return (
    <Pane height="100%" width="100%" padding={majorScale(2)}>
      <Pane
        display="flex"
        marginBottom={minorScale(3)}
        justifyContent="space-between"
        paddingBottom={majorScale(2)}
      >
        <CardHeading disabled={isDisabled} text="Contingency Risk" />
        {isConfigurable && (
          <ConfigureButtons
            isDisabled={isDisabled}
            cards={cards}
            setCards={setCards}
            name={name}
          />
        )}
        {!isConfigurable && <CardLinkButton href="/" />}
      </Pane>
      {showBlankSlate && <BlankSlate type={BlankSlateType.Contingency} />}
    </Pane>
  );
}
