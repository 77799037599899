import { useContext, useEffect, Fragment, useReducer } from "react";
import gql from "graphql-tag";
import { get } from "lodash";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import {
  OrganizationSelector,
  PortfolioProjectTable,
} from "components/templates";
import { Loadable, Pane } from "components/materials";
import { COMPARATORS, toBase64 } from "components/materials/FastDataTable";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import { getSearchByKey, removeKey } from "helpers/queryStringHelpers";
import { majorScale } from "helpers/utilities";
import { ScopeOrganizations } from "./ScopeOrganizations";
import { TABLE_QUERY } from "./PortfolioPage/queries";
import { dispatch } from "./PortfolioPage/tableConfig/reducer";

const ORG_QUERY = gql`
  query ReportsProjectsPageOrganizationsQuery($organizationId: String!) {
    organization(id: $organizationId) {
      id
      productTypes {
        id
        type
      }
      projectRegions {
        id
        region
      }
      projectTemplates {
        id
        name
        tasks {
          id
          name
        }
        customFields {
          id
          label
          type
        }
      }
      teams {
        id
        name
      }
    }
  }
`;

function ReportsProjectsPageInner({
  history,
  onOrganizationSelected,
  selectedOrganization,
}) {
  const {
    isDeveloper,
    isMultiOrgUser,
    hasPermissionAcrossProfiles,
  } = useContext(UserContext);

  const referrerOrgId = getSearchByKey(history, "referrerSelectedOrgId");

  const [viewConfig, dispatchViewConfig] = useReducer(dispatch, {
    configLoaded: false,
  });

  const multiOrgNeedToChangeOrg =
    referrerOrgId && referrerOrgId !== selectedOrganization.id;

  const { data: orgData, loading: orgLoading } = useQuery(ORG_QUERY, {
    skip: multiOrgNeedToChangeOrg,
    variables: { organizationId: selectedOrganization.id },
  });

  const [
    getProjects,
    { data: projectData, loading: projectLoading },
  ] = useLazyQuery(TABLE_QUERY);

  useEffect(() => {
    if (!multiOrgNeedToChangeOrg && viewConfig.configLoaded) {
      const variables = {
        ...viewConfig.tableConfig,
        filters: [
          ...viewConfig.tableConfig.filters,
          {
            field: "organization_id",
            comparator: "=",
            value: selectedOrganization.id,
          },
        ],
      };
      getProjects({ variables });
    }
  }, [
    viewConfig.configLoaded,
    viewConfig.tableConfig,
    multiOrgNeedToChangeOrg,
    getProjects,
    selectedOrganization.id,
  ]);

  if (multiOrgNeedToChangeOrg) {
    removeKey(history, "referrerSelectedOrgId");
    onOrganizationSelected({ id: referrerOrgId });
    return <Loadable loading />;
  }

  const loading = !viewConfig.configLoaded || orgLoading || projectLoading;

  const DEFAULT_VIEWS = [
    {
      config: toBase64({
        columnConfig: [
          "projectName",
          "projectTotal",
          isDeveloper ? "equityCommitted" : "commitments",
          "debtCommitted",
          "amountRemaining",
          "percentComplete",
        ],
        filterConfig: [
          {
            enum: ["Active", "Pre-Development", "Under Contract"],
            input: ["Active", "Pre-Development", "Under Contract"],
            key: "projectStatus",
            operator: COMPARATORS.EXACT.value,
          },
        ],
        groupConfig: { expanded: undefined },
        sortConfig: {},
      }),
      name: "Completion",
      isDefault: true,
    },
    {
      config: toBase64({
        columnConfig: [
          "projectName",
          "state",
          "city",
          "projectTotal",
          "amountRemaining",
        ],
        filterConfig: [],
        groupConfig: { expanded: undefined },
        sortConfig: {},
      }),
      name: "Geography",
      isDefault: true,
    },
    {
      config: toBase64({
        columnConfig: [
          "projectName",
          "projectTotal",
          "commitments",
          "currentDraw",
          "percentRemaining",
          "lastDrawFunded",
        ],
        filterConfig: [],
        groupConfig: { expanded: undefined },
        sortConfig: {},
      }),
      name: "Draw",
      isDefault: true,
    },
    {
      config: toBase64({
        columnConfig: [
          "projectName",
          "percentComplete",
          "interestReservesRemaining",
          "monthsRemaining",
          "failedRules",
        ],
        filterConfig: [],
        groupConfig: { expanded: undefined },
        sortConfig: {},
      }),
      name: "Rules",
      isDefault: true,
    },
  ];

  const canCreateProjects =
    !isMultiOrgUser &&
    hasPermissionAcrossProfiles(PERMISSION_ACTION.CREATE_PROJECT);

  const projects = get(projectData, "projects", []);
  const defaultOrgData = {
    projectTemplates: [],
    productTypes: [],
    projectRegions: [],
    teams: [],
  };

  return (
    <Fragment>
      <Loadable loading={loading} />
      <PortfolioProjectTable
        canCreateProjects={canCreateProjects}
        dataLoading={loading}
        defaultViews={DEFAULT_VIEWS}
        history={history}
        isReport
        onConfigChange={(config) =>
          dispatchViewConfig({ type: "UPDATE_TABLE", value: config })
        }
        orgData={orgData?.organization || defaultOrgData}
        paginatedProjects={projects}
        selectedOrganization={selectedOrganization}
        tableName="ReportsProjectTable"
      />
    </Fragment>
  );
}

export function ReportsProjectsPage({ history }) {
  return (
    <ScopeOrganizations scopeToUserPermission={PERMISSION_ACTION.RUN_REPORT}>
      {({
        onOrganizationSelected,
        allOrganizations,
        disabledOrganizations,
        selectedOrganization,
      }) => (
        <Fragment>
          <Pane margin={majorScale(2)}>
            <OrganizationSelector
              onOrganizationSelected={onOrganizationSelected}
              disabledOrganizations={disabledOrganizations}
              organizations={allOrganizations}
              selectedOrganization={selectedOrganization}
              title="Viewing projects for"
            />
          </Pane>
          <ReportsProjectsPageInner
            history={history}
            onOrganizationSelected={onOrganizationSelected}
            selectedOrganization={selectedOrganization}
          />
        </Fragment>
      )}
    </ScopeOrganizations>
  );
}
