import { createContext, useState, useEffect } from "react";
import gql from "graphql-tag";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { Loadable } from "components/materials";
import { get, find } from "lodash";
import analytics from "helpers/analytics";
import { useFeatureFlags } from "../../FeatureFlags";
import { userContextHelpers } from "./userContextHelpers";
import { useAuth } from "../../Auth";

const USER_CONTEXT_QUERY = gql`
  query UserContextQuery {
    organizations {
      id
      name
      demoAccount
      inactiveAccount
      insertedAt
      phoneNumbers
      emailAddresses
      type
      permissionConfig
      tier
    }
    user {
      id
      trackingId
      approvalAmountMinimum
      approvalAmountLimit
      email
      fullName
      firstName
      lastName
      insertedAt
      registeredAt
      permissionConfig
    }
    userProfiles {
      id
      trackingId
      apiToken
      approvalAmountMinimum
      approvalAmountLimit
      email
      fullName
      firstName
      lastName
      hideExhaustedAgreementsInDocReview
      insertedAt
      registeredAt
      organization {
        id
        name
      }
      parseDocumentInformation
      permissionConfig
      showDashboardCards
      showDashboardTodos
      showAgreementsCharts
      showProjectionCharts
      showBudgetCharts
      budgetChartView
      signatures
    }
  }
`;

const PROJECT_QUERY = gql`
  query UserContextProjectQuery($projectId: String!) {
    project(id: $projectId) {
      id
      organization {
        id
        name
        type
        permissionConfig
      }
    }
  }
`;

const QA_ORGANIZATION_PREFIX = "Rabbet QA Org";
const QA_USER_EMAIL = "rabbettestcafe@gmail.com";
const QA_USER_KEY = "automated-rabbet-qa-user";
const QA_USER_NAME = "Rabbet QA User (Automated)";

export const UserContext = createContext({
  email: undefined,
  hasPermission: () => false,
  hasPermissionAcrossProfiles: () => false,
  hasOrgLevelPermission: () => false,
  isDeveloper: false,
  isMultiOrgUser: false,
  listProfilesForPermission: () => [],
  organizationId: undefined,
  organizationName: undefined,
  scopeOrgsToUserLevelPermission: () => false,
  user: undefined,
  userProfiles: [],
  userFullName: undefined,
  userId: undefined,
});

export function UserContextProvider({ children, projectId, organization }) {
  const auth = useAuth();
  const { data, loading, refetch: refetchUserContextQuery } = useQuery(
    USER_CONTEXT_QUERY,
    {
      skip: !auth.isAuthenticated(),
    }
  );

  const [scopedOrganization, setScopedOrganization] = useState(organization);

  const [getProjectQuery, projectQuery] = useLazyQuery(PROJECT_QUERY, {
    variables: { projectId },
    onCompleted: (result) =>
      setScopedOrganization(get(result, "project.organization")),
  });

  if (projectId && !projectQuery.called) {
    getProjectQuery();
  }

  const user = scopedOrganization
    ? find(get(data, "userProfiles", []), [
        "organization.id",
        scopedOrganization.id,
      ]) || {}
    : get(data, "user", {});

  const fullOrganization = scopedOrganization
    ? find(get(data, "organizations", []), ["id", scopedOrganization.id]) || {}
    : get(data, "organizations.0", {});

  const featureFlags = useFeatureFlags();

  useEffect(() => {
    if (user && user.id && fullOrganization) {
      analytics.identify({
        ...user,
        organization: fullOrganization,
      });

      /**
       * Our automated testing on staging (i.e., via `Rabbet/bevel`) automates
       * the creation of unique users. Such users precipitate a crisis with
       * LaunchDarkly. We are exceeding our plan's allowance of client-side
       * Monthly Active Users (i.e., MAUs) by 489%.
       *
       * Now therefore, the following code endeavors to consolidate said users
       * into a single MAU by assigning a common `key`. We know we are handling
       * an automated user because:
       *
       * - The said user's organization name will begin with `Rabbet QA Org`
       *   (e.g., `Rabbet QA Org 680480`); and
       * - The said user's email address will begin with `rabbettestcafe` (e.g.,
       *   `rabbettestcafe+61578d37-43c6-467e-a16e-6b9e5a7e7f6d@gmail.com`).
       *
       * References:
       *
       * - https://docs.launchdarkly.com/home/users/anonymous-users/
       * - https://docs.launchdarkly.com/home/billing/usage-metrics
       */
      const isQaUser = fullOrganization.name?.startsWith(
        QA_ORGANIZATION_PREFIX
      );

      const attrs = isQaUser
        ? {
            email: QA_USER_EMAIL,
            key: QA_USER_KEY,
            kind: "user",
            name: QA_USER_NAME,
          }
        : {
            kind: "multi",
            organization: {
              key: fullOrganization.id,
              name: fullOrganization.name,
            },
            user: {
              email: user.email,
              key: user.id,
              name: user.fullName,
            },
          };

      featureFlags.identifyUser(attrs);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id, fullOrganization.id]);

  if (!auth.isAuthenticated()) return null;

  if (projectQuery.loading || loading) return <Loadable loading />;

  return (
    <UserContext.Provider
      value={{
        ...userContextHelpers({
          ...data,
          user,
          scopedOrganization,
        }),
        refetchUserContextQuery,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
