import { useContext, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { ArrowRightIcon } from "evergreen-ui";
import { filter as lodashFilter, flatMap, orderBy } from "lodash";
import {
  ConfigureButtons,
  Pane,
  Shortener,
  Tab,
  Tablist,
  Text,
} from "components/materials";
import { LINE_ITEM_TYPE } from "helpers/enums";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { divide, sumBy } from "helpers/math";
import { formatCurrency } from "helpers/formatCurrency";
import { formatNumber } from "helpers/formatNumber";
import t from "helpers/translate";
import { hover } from "glamor";
import { BlankSlate, BlankSlateType } from "./BlankSlate";
import { CardHeading } from "./CardHeading";
import { CardLinkButton } from "./CardLinkButton";

export const PROJECT_COSTS_CONFIGURATION_SETTINGS = {
  i: "projectCosts",
  x: 0,
  y: 5,
  w: 1,
  h: 1,
  disabled: false,
};

const TYPE_OPTIONS = {
  ACRES: "acres",
  SQUARE_FEET: "squareFeet",
};

const LINE_ITEM_CATEGORIES = {
  [LINE_ITEM_TYPE.ALLOWANCES]: LINE_ITEM_TYPE.ALLOWANCES,
  [LINE_ITEM_TYPE.CONTINGENCY]: LINE_ITEM_TYPE.CONTINGENCY,
  [LINE_ITEM_TYPE.DEVELOPER_FEES]: LINE_ITEM_TYPE.DEVELOPER_FEES,
  [LINE_ITEM_TYPE.FEES]: LINE_ITEM_TYPE.FEES,
  [LINE_ITEM_TYPE.FINANCING]: LINE_ITEM_TYPE.FINANCING,
  [LINE_ITEM_TYPE.INTEREST_RESERVES]: LINE_ITEM_TYPE.INTEREST_RESERVES,
  [LINE_ITEM_TYPE.LAND_ACQUISITION]: LINE_ITEM_TYPE.LAND_ACQUISITION,
  [LINE_ITEM_TYPE.TENANT_IMPROVEMENTS]: LINE_ITEM_TYPE.TENANT_IMPROVEMENTS,
  NONE: "NONE",
};

function getCategoryTotal(lineItems, category) {
  let filteredLineItems;
  if (category === LINE_ITEM_CATEGORIES.NONE) {
    filteredLineItems = lineItems.filter(({ types }) => {
      const categories = types.filter(
        (type) =>
          type === LINE_ITEM_TYPE.HARD_COSTS ||
          type === LINE_ITEM_TYPE.SOFT_COSTS
      );
      return categories.length === 0;
    });
  } else {
    filteredLineItems = lineItems.filter(({ types }) =>
      types.includes(category)
    );
  }

  return sumBy(filteredLineItems, "budgetAmount");
}

function getCategoryTotals(lineItems) {
  return orderBy(
    Object.keys(LINE_ITEM_CATEGORIES).map((category) => ({
      category,
      total: getCategoryTotal(lineItems, category),
    })),
    "total",
    "desc"
  );
}

function getProjectTotalNumbers(projects) {
  return {
    squareFeet: {
      total: sumBy(projects, "squareFeet"),
      projects: lodashFilter(projects, "squareFeet"),
    },
    acres: {
      total: sumBy(projects, "acres"),
      projects: lodashFilter(projects, "acres"),
    },
  };
}

function CategoryValue({
  categoryAverage,
  key,
  maxWidthAmount,
  name,
  navigateToProjects,
  theme,
}) {
  return (
    <Pane display="flex" marginBottom={majorScale(1)} key={key}>
      <Pane
        display="flex"
        alignItems="center"
        cursor="pointer"
        onClick={navigateToProjects}
        width={284}
        marginRight={majorScale(2)}
      >
        <Shortener
          color={theme.colors.linkBlue}
          className={hover({
            textDecoration: "underline",
          })}
          limit={20}
          size={200}
          text={t(`lineItemTypes.${name}`)}
        />
        <ArrowRightIcon
          color={theme.colors.linkBlue}
          icon={ArrowRightIcon}
          appearance="minimal"
          marginLeft="auto"
        />
      </Pane>
      <Pane width="100%" display="flex">
        {categoryAverage !== 0 && (
          <Pane
            paddingRight={minorScale(1)}
            display="flex"
            width={`${maxWidthAmount}%`}
            height={30}
            background={theme.colors.barChartBlue}
          />
        )}
        <Pane marginLeft={minorScale(1)} marginTop={minorScale(1)}>
          <Text color={theme.colors.gray700}>
            {formatCurrency(categoryAverage, { shortenedFormat: true })}
          </Text>
        </Pane>
      </Pane>
    </Pane>
  );
}

export function ProjectCosts({
  cards,
  isConfigurable,
  isDisabled,
  name,
  projects,
  setCards,
}) {
  const history = useHistory();
  const theme = useContext(ThemeContext);
  const [dataType, setDataType] = useState(TYPE_OPTIONS.SQUARE_FEET);

  const projectTotals = useMemo(() => getProjectTotalNumbers(projects), [
    projects,
  ]);

  const filteredProjects = projectTotals[dataType].projects;
  const lineItems = flatMap(filteredProjects, ({ lineItems }) => lineItems);

  const totalArea = projectTotals[dataType].total;
  const averageArea = divide(
    projectTotals[dataType].total,
    projectTotals[dataType].projects.length
  );

  const categories = getCategoryTotals(lineItems);

  const showBlankSlate = projects.length === 0;

  return (
    <Pane height="100%" width="100%" padding={majorScale(2)}>
      <Pane
        display="flex"
        marginBottom={minorScale(3)}
        justifyContent="space-between"
        paddingBottom={majorScale(2)}
      >
        <CardHeading disabled={isDisabled} text="Project Costs" />
        {isConfigurable && (
          <ConfigureButtons
            isDisabled={isDisabled}
            cards={cards}
            setCards={setCards}
            name={name}
          />
        )}
        {!isConfigurable && <CardLinkButton href="/reports/projects" />}
      </Pane>
      <Pane display="flex" justifyContent="space-between">
        <Pane>
          <Tablist>
            <Tab
              aria-controls={`panel-${TYPE_OPTIONS.SQUARE_FEET}`}
              isSelected={dataType === TYPE_OPTIONS.SQUARE_FEET}
              key={TYPE_OPTIONS.SQUARE_FEET}
              onSelect={() => setDataType(TYPE_OPTIONS.SQUARE_FEET)}
              height={majorScale(3)}
              color={theme.gray700}
            >
              Sq Ft
            </Tab>
            <Tab
              aria-controls={`panel=${TYPE_OPTIONS.ACRES}`}
              isSelected={dataType === TYPE_OPTIONS.ACRES}
              key={TYPE_OPTIONS.ACRES}
              onSelect={() => setDataType(TYPE_OPTIONS.ACRES)}
              height={majorScale(3)}
              color={theme.gray700}
            >
              Acreage
            </Tab>
          </Tablist>
        </Pane>
        <Pane>
          <Text paddingRight={minorScale(1)} color={theme.gray700}>
            Avg {dataType === TYPE_OPTIONS.SQUARE_FEET ? "Sq Ft" : "acres"}
          </Text>
          <Text size={600} color={theme.gray700}>
            {formatNumber(averageArea)}
          </Text>
        </Pane>
      </Pane>
      <Pane height="66%" paddingTop={majorScale(2)}>
        <Pane height="100%" overflowY="scroll">
          {categories.map(({ category, total }) => {
            const categoryAverage = divide(total, totalArea);
            const maxValue = divide(categories[0]?.total, totalArea);
            const maxWidthAmount = divide(categoryAverage, maxValue) * 100;
            const navigateToProjects = () => {
              history.push("/reports/projects");
            };

            return (
              <CategoryValue
                categoryAverage={categoryAverage}
                key={category}
                maxWidthAmount={maxWidthAmount}
                name={category}
                navigateToProjects={navigateToProjects}
                theme={theme}
              />
            );
          })}
        </Pane>
      </Pane>
      <Pane marginTop={minorScale(3)} textAlign="center">
        <Text fontStyle="italic" color={theme.colors.gray700}>
          Note: The values shown indicate the average Cost/
          {dataType === TYPE_OPTIONS.SQUARE_FEET ? "Sq Ft" : "acre"} for each
          category
        </Text>
      </Pane>
      {showBlankSlate && <BlankSlate type={BlankSlateType.ProjectCosts} />}
    </Pane>
  );
}
