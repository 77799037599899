import { useContext } from "react";
import { hover } from "glamor";
import { Pane, Link, IconButton } from "components/materials";
import { ThemeContext, ArrowRightIcon } from "evergreen-ui";

export function CardLinkButton({ href }) {
  const theme = useContext(ThemeContext);
  return (
    <Pane
      width={32}
      height={32}
      display="flex"
      alignItems="center"
      justifyContent="center"
      border={`1px solid ${theme.colors.gray400}`}
      borderRadius={4}
      className={hover({
        background: theme.colors.gray100,
        cursor: "pointer",
      })}
    >
      <Pane marginTop="5px">
        <IconButton
          is={Link}
          to={href}
          icon={ArrowRightIcon}
          appearance="minimal"
        />
      </Pane>
    </Pane>
  );
}
