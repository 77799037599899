import { useContext } from "react";
import { ArrowDownIcon, ArrowUpIcon } from "evergreen-ui";
import { Pane, Text } from "components/materials";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";

export function Header({
  columns,
  currentSortDirection,
  currentSortKey,
  setSortKey,
  setSortDirection,
}) {
  const theme = useContext(ThemeContext);
  const borderStyle = `1px solid ${theme.colors.borderGray}`;

  return (
    <thead
      style={{
        background: "white",
        borderBottom: borderStyle,
        position: "sticky",
        top: -1,
        cursor: "pointer",
      }}
    >
      <tr>
        {columns.map((column, index) => {
          const onHeaderClick = () => {
            const nextDirection =
              currentSortKey !== column.sortKey ||
              currentSortDirection === "desc"
                ? "asc"
                : "desc";

            setSortDirection(nextDirection);
            setSortKey(column.sortKey);
          };

          return (
            <th
              align={column.headerAlign}
              height="36px"
              key={`cell-${index}`}
              onClick={onHeaderClick}
              width={column.width}
            >
              <Pane
                alignItems="center"
                display="flex"
                padding={minorScale(3)}
                width="fit-content"
              >
                <Text
                  color={theme.colors.textGray}
                  fontWeight={500}
                  size={400}
                  userSelect="none"
                >
                  {column.name}
                </Text>
                <SortIcon
                  column={column}
                  currentSortDirection={currentSortDirection}
                  currentSortKey={currentSortKey}
                />
              </Pane>
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

function SortIcon({ column, currentSortDirection, currentSortKey }) {
  const theme = useContext(ThemeContext);

  if (currentSortKey !== column.sortKey) return null;

  const IconComponent =
    currentSortDirection === "asc" ? ArrowUpIcon : ArrowDownIcon;

  return (
    <IconComponent marginLeft={majorScale(1)} color={theme.colors.textGray} />
  );
}
