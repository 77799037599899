import { useContext } from "react";
import { Button, Pane, Pill, Text, Tooltip } from "components/materials";
import { PERMISSION_ACTION } from "helpers/enums";
import { UserContext } from "helpers/behaviors";
import { majorScale } from "helpers/utilities";
import { divide } from "helpers/math";
import formatPercent from "helpers/formatPercent";
import { getMergeSearch, getSearchByKey } from "helpers/queryStringHelpers";
import {
  prepareDocuments,
  getCompletedDocumentsCounts,
} from "../../DrawOverviewPage/Documents";
import {
  getBudgetRatio,
  getDocumentApprovalsRatio,
  getDrawApprovalsRatio,
  getDrawAssessmentsRatio,
  getFundingSourcesRatio,
  getInspectionRatio,
  getPaymentsRatio,
  getRulesRatio,
  getRatioColor,
  showPaymentsTab,
} from "./statusFormulas";

export function DrawStatusBar({ draw, project, history }) {
  const { hasPermission } = useContext(UserContext);
  const showDocumentApprovalStatus =
    hasPermission(PERMISSION_ACTION.TIERED_DOCUMENT_REVIEWERS) &&
    project.documentReviewers.length > 0;

  const showInspection = hasPermission(
    PERMISSION_ACTION.INSPECTION_REPORT_WORKFLOW
  );

  const shouldShowPaymentsTab = showPaymentsTab(hasPermission);

  const hasDrawOverviewPage = hasPermission(
    PERMISSION_ACTION.DRAW_OVERVIEW_PAGE
  );

  const navigateToDocuments = () => {
    const route = `/projects/${project.id}/draws/${draw.id}/documentation`;
    history.push(route);
  };

  const navigateToBudget = () => {
    const route = `/projects/${project.id}/draws/${draw.id}/line_items`;
    history.push(route);
  };

  const navigateToFundingSources = () => {
    const route = `/projects/${project.id}/draws/${draw.id}/funding_sources`;
    history.push(route);
  };

  const navigateToInspection = () => {
    const route = `/projects/${project.id}/draws/${draw.id}/inspection`;
    history.push(route);
  };

  const navigateToPayments = () => {
    const route = `/projects/${project.id}/draws/${draw.id}/payment`;
    history.push(route);
  };

  const navigateToDrawAssessment = () => {
    if (!hasDrawOverviewPage) {
      history.push(`/projects/${project.id}/draws/${draw.id}/reviews`);
      return;
    }

    const route = `/projects/${project.id}/draws/${
      draw.id
    }/overview?${getMergeSearch(history, { showDrawAssessment: true })}`;
    history.push(route);
  };

  const navigateToApprovals = () => {
    if (!hasDrawOverviewPage) {
      history.push(`/projects/${project.id}/draws/${draw.id}/reviews`);
      return;
    }

    const focusDrawApprovals = getSearchByKey(history, "focusDrawApprovals");
    // once the draw approvals have been focused, the search key will be present in the query string (it is not removed after scrolling)
    // if we click the pill to focus again and find that key presnet, we know we are already on the Draw Overview Page and can just scroll rather than navigate
    if (focusDrawApprovals) {
      const element = window.document.querySelector(
        `[id="drawSummaryApprovals"]`
      );
      // Ensure the element was found and has the needed function
      typeof element?.scrollIntoView === "function" &&
        element.scrollIntoView({ behavior: "smooth" });
      return;
    }
    const route = `/projects/${project.id}/draws/${
      draw.id
    }/overview?${getMergeSearch(history, { focusDrawApprovals: true })}`;
    history.push(route);
  };

  // once the rules have been focused, the search key will be present in the query string (it is not removed after scrolling)
  // if we click the pill to focus again and find that key presnet, we know we are already on the Draw Overview Page and can just scroll rather than navigate
  const navigateToRules = () => {
    if (!hasDrawOverviewPage) {
      history.push(`/projects/${project.id}/draws/${draw.id}/reviews`);
      return;
    }

    const focusRules = getSearchByKey(history, "focusRules");

    if (focusRules) {
      const element = window.document.querySelector(`[id="drawOverviewRules"]`);
      // Ensure the element was found and has the needed function
      typeof element?.scrollIntoView === "function" &&
        element.scrollIntoView({ behavior: "smooth" });
      return;
    }
    const route = `/projects/${project.id}/draws/${
      draw.id
    }/overview?${getMergeSearch(history, { focusRules: true })}`;
    history.push(route);
  };

  return (
    <Pane
      height={48}
      minHeight={48}
      elevation={1}
      paddingX={majorScale(4)}
      zIndex="2"
      display="flex"
      alignItems="center"
      backgroundColor="#FAFBFF"
    >
      <DocumentsDetail
        draw={draw}
        project={project}
        navigateToDocuments={navigateToDocuments}
      />
      {showDocumentApprovalStatus && (
        <DocApprovalsDetail
          draw={draw}
          project={project}
          navigateToDocuments={navigateToDocuments}
        />
      )}
      <BudgetDetail
        draw={draw}
        project={project}
        navigateToBudget={navigateToBudget}
      />
      <RulesDetail
        draw={draw}
        project={project}
        navigateToRules={navigateToRules}
      />
      <FundingSourcesDetail
        draw={draw}
        project={project}
        navigateToFundingSources={navigateToFundingSources}
      />
      {showInspection && (
        <InspectionDetail
          draw={draw}
          project={project}
          navigateToInspection={navigateToInspection}
        />
      )}
      {draw.questions.length > 0 && (
        <DrawAssessmentDetail
          draw={draw}
          project={project}
          navigateToDrawAssessment={navigateToDrawAssessment}
        />
      )}
      <DrawApprovalsDetail
        draw={draw}
        project={project}
        navigateToApprovals={navigateToApprovals}
      />
      {shouldShowPaymentsTab && (
        <PaymentsDetail
          draw={draw}
          project={project}
          navigateToPayments={navigateToPayments}
        />
      )}
    </Pane>
  );
}

function DrawStatusDetail({
  label,
  numerator,
  denominator,
  onClick,
  tooltipContent,
  showPercentage,
}) {
  return (
    <Tooltip content={tooltipContent}>
      <Button onClick={onClick} appearance="minimal">
        <Text fontWeight={700}>{label}</Text>
        <DrawStatusDetailPill
          numerator={numerator}
          denominator={denominator}
          showPercentage={showPercentage}
        />
      </Button>
    </Tooltip>
  );
}

function DrawStatusDetailPill({ numerator, denominator, showPercentage }) {
  if (showPercentage) {
    const percentage =
      denominator === 0 ? null : divide(numerator, denominator);
    return (
      <Pill
        color={percentage !== null && percentage < 1 ? "red" : "green"}
        marginLeft={majorScale(1)}
      >
        {formatPercent(percentage)}
      </Pill>
    );
  }

  return (
    <Pill
      color={getRatioColor(numerator, denominator)}
      marginLeft={majorScale(1)}
    >{`${numerator} / ${denominator}`}</Pill>
  );
}

function DocumentsDetail({ draw, project, navigateToDocuments }) {
  const preparedDocumentGroups = prepareDocuments(
    draw.drawOverviewDocuments,
    project.documentReviewers
  );

  const { numerator, denominator } = getCompletedDocumentsCounts(
    preparedDocumentGroups
  );

  return (
    <DrawStatusDetail
      label="Documents"
      numerator={numerator}
      denominator={denominator}
      onClick={navigateToDocuments}
      tooltipContent="Number of documents that have been properly allocated / Number of total documents uploaded this draw. If this is Yellow, documents are missing information."
    />
  );
}

function DocApprovalsDetail({ draw, project, navigateToDocuments }) {
  const { numerator, denominator } = getDocumentApprovalsRatio({
    draw,
    project,
  });

  return (
    <DrawStatusDetail
      label="Doc Approvals"
      numerator={numerator}
      denominator={denominator}
      onClick={navigateToDocuments}
      tooltipContent="Number of documents that have been approved / Number of documents that need to be approved. If this is Yellow, documents are in need of approval."
    />
  );
}

function BudgetDetail({ draw, navigateToBudget }) {
  const { numerator, denominator } = getBudgetRatio({ draw });

  return (
    <DrawStatusDetail
      label="Budget"
      numerator={numerator}
      denominator={denominator}
      onClick={navigateToBudget}
      tooltipContent="Number of line items requested this draw / Number of line items requested this draw that have sufficient budget remaining. If this is Red, some line items are overdrawn on the budget."
    />
  );
}

function RulesDetail({ draw, navigateToRules }) {
  const { numerator, denominator } = getRulesRatio({ draw });

  return (
    <DrawStatusDetail
      label="Rules"
      numerator={numerator}
      denominator={denominator}
      onClick={navigateToRules}
      tooltipContent="Number of rules that have been passed / Number of rules on the draw. If this is Yellow, rules are pending."
    />
  );
}

function FundingSourcesDetail({ draw, navigateToFundingSources }) {
  const { numerator, denominator } = getFundingSourcesRatio({ draw });

  return (
    <DrawStatusDetail
      label="Funding Sources"
      numerator={numerator}
      denominator={denominator}
      onClick={navigateToFundingSources}
      showPercentage
      tooltipContent="Percent of the amount requested this draw allocated to funding sources. If this is Red, funding sources are in need of allocation."
    />
  );
}

function InspectionDetail({ draw, navigateToInspection }) {
  const { numerator, denominator } = getInspectionRatio({ draw });

  return (
    <DrawStatusDetail
      label="Inspection"
      numerator={numerator}
      denominator={denominator}
      onClick={navigateToInspection}
      tooltipContent="If this is Yellow, the draw has not yet received an expected inspection."
    />
  );
}

function DrawAssessmentDetail({ draw, navigateToDrawAssessment }) {
  const { numerator, denominator } = getDrawAssessmentsRatio({ draw });

  return (
    <DrawStatusDetail
      label="Draw Assessment"
      numerator={numerator}
      denominator={denominator}
      onClick={navigateToDrawAssessment}
      tooltipContent="Number of completed draw assessment fields / Number of draw assessment fields on the project. If this is Yellow, this draw has draw assessments fields that are missing information."
    />
  );
}

function DrawApprovalsDetail({ draw, navigateToApprovals }) {
  const { numerator, denominator } = getDrawApprovalsRatio({ draw });

  return (
    <DrawStatusDetail
      label="Draw Approvals"
      numerator={numerator}
      denominator={denominator}
      onClick={navigateToApprovals}
      tooltipContent="Number of draw approvals received / Number of draw approvals. If this is Yellow, draw approvals are pending."
    />
  );
}

function PaymentsDetail({ draw, navigateToPayments }) {
  const { numerator, denominator } = getPaymentsRatio({ draw });

  return (
    <DrawStatusDetail
      label="Payments Posted"
      numerator={numerator}
      denominator={denominator}
      onClick={navigateToPayments}
      tooltipContent="Number of payments posted / Number of payable documents. If this is Yellow, some payments have not been posted."
    />
  );
}
