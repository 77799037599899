import { useContext, Fragment } from "react";
import {
  Button,
  Card,
  Heading,
  Link,
  Pane,
  Paragraph,
  Table,
  Text,
  Tooltip,
} from "components/materials";
import { partition } from "lodash";
import { UserContext } from "helpers/behaviors";
import { DOCUMENT_STATE, PERMISSION_ACTION } from "helpers/enums";
import { formatCurrency } from "helpers/formatCurrency";
import { majorScale } from "helpers/utilities";
import {
  aggregateDocumentApprovalsData,
  isApprovableDocumentType,
  getNextOrderedReviewer,
} from "helpers/documentHelpers";
import t from "helpers/translate";
import { ApprovalTooltipContent } from "../../templates/DocumentTable";

export function Documents({ preparedDocumentGroups, draw, project, history }) {
  const { hasPermission } = useContext(UserContext);
  const canViewDocuments = hasPermission(PERMISSION_ACTION.DOWNLOAD_DOCUMENT);
  const canUploadDocuments = hasPermission(PERMISSION_ACTION.UPLOAD_DOCUMENT);
  const hasDocumentApprovals = hasPermission(
    PERMISSION_ACTION.TIERED_DOCUMENT_REVIEWERS
  );

  const navigateToDocuments = () => {
    const route = `/projects/${project.id}/draws/${draw.id}/documentation`;
    history.push(route);
  };

  const navigateToDocument = (documentId) => {
    const route = `/projects/${project.id}/draws/${draw.id}/documentation/${documentId}`;
    history.push(route);
  };

  const navigateToAddDocuments = () => {
    const route = `/projects/${project.id}/draws/${draw.id}/documentation?uploadDocument=true`;
    history.push(route);
  };

  const useApprovalStatus =
    hasDocumentApprovals && project.documentReviewers.length > 0;

  const { numerator, denominator } = getDocumentHeaderCounts(
    preparedDocumentGroups,
    useApprovalStatus
  );

  const { allDocuments } = preparedDocumentGroups;
  const hasDocuments = allDocuments.length > 0;

  return (
    <Pane borderBottom paddingY={majorScale(3)}>
      <Pane paddingX={majorScale(4)}>
        <Pane
          display="flex"
          justifyContent="space-between"
          alignItems="end"
          marginBottom={majorScale(3)}
        >
          <Pane display="flex">
            <Heading fontSize={16} fontWeight={600}>{`${
              useApprovalStatus ? "Document Approvals" : "Documents"
            }: ${numerator} / ${denominator}`}</Heading>
            <Text color="muted" fontSize={12} marginLeft={majorScale(2)}>
              This is a list of your project documents sorted by the documents
              that are most important either because they are missing
              information, need your approval, or are the most notable
              documents.
            </Text>
          </Pane>
          {hasDocuments && (
            <Link size={300} onClick={navigateToDocuments}>
              View All
            </Link>
          )}
        </Pane>
        {hasDocuments ? (
          <Card>
            <Table paddingBottom={0}>
              <Table.Head>
                <Table.Row>
                  <Table.TextHeaderCell>Vendor</Table.TextHeaderCell>
                  <Table.TextHeaderCell>Type</Table.TextHeaderCell>
                  <Table.TextHeaderCell>Status</Table.TextHeaderCell>
                  {hasDocumentApprovals && (
                    <Fragment>
                      <Table.TextHeaderCell>
                        Approval Status
                      </Table.TextHeaderCell>
                      <Table.TextHeaderCell>Next Approver</Table.TextHeaderCell>
                    </Fragment>
                  )}
                  <Table.TextHeaderCell textAlign="right">
                    Amount
                  </Table.TextHeaderCell>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {allDocuments.slice(0, 5).map((document) => {
                  return (
                    <Table.Row
                      key={document.id}
                      onClick={
                        canViewDocuments
                          ? () => navigateToDocument(document.id)
                          : null
                      }
                    >
                      <Table.TextCell>{document.vendorName}</Table.TextCell>
                      <Table.TextCell>
                        {t(`documentTypeName.${document.type}`)}
                      </Table.TextCell>
                      <Table.TextCell>{document.status}</Table.TextCell>
                      {hasDocumentApprovals && (
                        <Fragment>
                          <Table.TextCell>
                            {getApprovalStatus(document)}
                          </Table.TextCell>
                          <Table.TextCell>
                            {getNextApprover(document, project)}
                          </Table.TextCell>
                        </Fragment>
                      )}
                      <Table.TextCell textAlign="right">
                        {formatCurrency(document.displayAmount)}
                      </Table.TextCell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Card>
        ) : (
          <Fragment>
            <Paragraph>There are no documents on this draw.</Paragraph>
            {canUploadDocuments && (
              <Button
                marginTop={majorScale(2)}
                onClick={navigateToAddDocuments}
              >
                Add Documents
              </Button>
            )}
          </Fragment>
        )}
      </Pane>
    </Pane>
  );
}

export function prepareDocuments(documents, documentReviewers) {
  const formattedDocuments = documents.map((document) => {
    const isApprovable = isApprovableDocumentType(document);
    const {
      approvedCount,
      reviewersCount,
      approvedReviews,
      pendingReviewers,
    } = isApprovable
      ? aggregateDocumentApprovalsData(document, documentReviewers)
      : {
          approvedCount: null,
          reviewersCount: null,
          approvedReviews: [],
          pendingReviewers: [],
        };

    return {
      ...document,
      status: getDocumentStatus(document.state),
      displayAmount:
        (document.amount ||
          document.adjustedAmount ||
          document.agreementAmount) ??
        0,
      isApprovable,
      approvedCount,
      reviewersCount,
      approvedReviews,
      pendingReviewers,
    };
  });

  const sortedByAmount = formattedDocuments.sort(
    (documentA, documentB) => documentB.displayAmount - documentA.displayAmount
  );
  const [ignoredDocuments, activeDocuments] = partition(
    sortedByAmount,
    (document) => document.state === DOCUMENT_STATE.IGNORED
  );
  const [completeDocuments, documentsMissingInformation] = partition(
    activeDocuments,
    (document) => document.state === DOCUMENT_STATE.APPLIED
  );
  const [fullyApprovedDocuments, documentsNeedingApproval] = partition(
    completeDocuments,
    (document) => {
      if (!document.isApprovable) return true;
      return document.approvedCount === document.reviewersCount;
    }
  );
  const sortedDocuments = documentsMissingInformation
    .concat(documentsNeedingApproval)
    .concat(fullyApprovedDocuments)
    .concat(ignoredDocuments);

  return {
    allDocuments: sortedDocuments,
    activeDocuments,
    completeDocuments,
  };
}

export function getCompletedDocumentsCounts(preparedDocumentGroups) {
  const { activeDocuments, completeDocuments } = preparedDocumentGroups;
  return {
    numerator: completeDocuments.length,
    denominator: activeDocuments.length,
  };
}

export function getFullyApprovedDocumentsCounts(preparedDocumentGroups) {
  const { activeDocuments } = preparedDocumentGroups;
  const approvableDocuments = activeDocuments.filter(
    ({ isApprovable }) => isApprovable
  );

  const fullyApprovedDocuments = approvableDocuments.filter(
    ({ approvedCount, reviewersCount }) => {
      return approvedCount === reviewersCount;
    }
  );

  return {
    numerator: fullyApprovedDocuments.length,
    denominator: approvableDocuments.length,
  };
}

function getDocumentHeaderCounts(preparedDocumentGroups, useApprovalStatus) {
  if (!useApprovalStatus) {
    return getCompletedDocumentsCounts(preparedDocumentGroups);
  }

  return getFullyApprovedDocumentsCounts(preparedDocumentGroups);
}

function getDocumentStatus(documentState) {
  if (documentState === DOCUMENT_STATE.IGNORED) return "Ignored";
  if (documentState === DOCUMENT_STATE.APPLIED) return "Complete";
  return "Missing Information";
}

function getNextApprover(document, project) {
  if (!document.isApprovable) return null;
  if (project.documentReviewers.length === 0) return "Pending Setup";
  return getNextOrderedReviewer(document, project.documentReviewers)?.user
    ?.fullName;
}

function getApprovalStatus(document) {
  if (!document.isApprovable) return null;

  const {
    approvedCount,
    reviewersCount,
    approvedReviews,
    pendingReviewers,
  } = document;

  const showApprovalStatusTooltip =
    approvedReviews.length > 0 || pendingReviewers.length > 0;

  return showApprovalStatusTooltip ? (
    <Tooltip
      content={
        <ApprovalTooltipContent
          approvedReviews={approvedReviews}
          pendingReviewers={pendingReviewers}
        />
      }
    >
      <Text size={300}>{`${approvedCount} / ${reviewersCount}`}</Text>
    </Tooltip>
  ) : (
    `${approvedCount} / ${reviewersCount}`
  );
}
