import { add, divide, subtract } from "helpers/math";
import { includes, isNull, map, reject } from "lodash";
import { LINE_ITEM_TYPE } from "helpers/enums";
import { getPieChartColor } from "helpers/colors";

function getLineItemData(lineItems) {
  return lineItems.reduce(
    (
      {
        lineItemData,
        drawnContingencyAmount,
        contingencyBalanceToFund,
        contingencyOriginalBudgetAmount,
      },
      lineItem
    ) => {
      const {
        id,
        balanceToFundAmount,
        division,
        hardCosts,
        name,
        originalBudgetAmount,
        requestedToDateAmount,
        types,
      } = lineItem;
      const isContingency = includes(types, LINE_ITEM_TYPE.CONTINGENCY);

      return {
        lineItemData: {
          ...lineItemData,
          [id]: {
            name: `${division.name} - ${name}`,
            isContingency,
            hardCosts,
          },
        },
        drawnContingencyAmount: add(
          drawnContingencyAmount,
          isContingency ? requestedToDateAmount : 0
        ),
        contingencyBalanceToFund: add(
          contingencyBalanceToFund,
          isContingency ? balanceToFundAmount : 0
        ),
        contingencyOriginalBudgetAmount: add(
          contingencyOriginalBudgetAmount,
          isContingency ? originalBudgetAmount : 0
        ),
      };
    },
    {
      lineItemData: {},
      drawnContingencyAmount: 0,
      contingencyBalanceToFund: 0,
      contingencyOriginalBudgetAmount: 0,
    }
  );
}

function getAdjustmentData({ transactions }, lineItemData) {
  const filteredTransactions = transactions.filter(
    ({ lineItemId }) => lineItemData[lineItemId]
  );

  return filteredTransactions.reduce(
    (
      {
        contingencyNegativeAdjustedAmount,
        nonContingencyNegativeTransactionAmount,
        nonContingencyPositiveTransactionAmount,
        contingencyPositiveAdjustedAmount,
        nonContingencyPositiveTransactions,
      },
      transaction
    ) => {
      const { lineItemId, amount } = transaction;

      const { isContingency } = lineItemData[lineItemId];

      return {
        contingencyNegativeAdjustedAmount:
          isContingency && amount < 0
            ? add(contingencyNegativeAdjustedAmount, amount)
            : contingencyNegativeAdjustedAmount,
        nonContingencyNegativeTransactionAmount:
          !isContingency && amount < 0
            ? add(nonContingencyNegativeTransactionAmount, amount)
            : nonContingencyNegativeTransactionAmount,
        nonContingencyPositiveTransactionAmount:
          !isContingency && amount > 0
            ? add(nonContingencyPositiveTransactionAmount, amount)
            : nonContingencyPositiveTransactionAmount,
        contingencyPositiveAdjustedAmount:
          isContingency && amount > 0
            ? add(contingencyPositiveAdjustedAmount, amount)
            : contingencyPositiveAdjustedAmount,
        nonContingencyPositiveTransactions: [
          ...nonContingencyPositiveTransactions,
          ...(amount > 0 && !isContingency ? [transaction] : []),
        ],
      };
    },
    {
      contingencyNegativeAdjustedAmount: 0,
      nonContingencyNegativeTransactionAmount: 0,
      nonContingencyPositiveTransactionAmount: 0,
      contingencyPositiveAdjustedAmount: 0,
      nonContingencyPositiveTransactions: [],
    }
  );
}

export function prepareBucketAmounts(budgetAdjustments, lineItemData) {
  return budgetAdjustments.reduce(
    (acc, adjustment) => {
      const {
        contingencyNegativeAdjustedAmount,
        nonContingencyNegativeTransactionAmount,
        nonContingencyPositiveTransactionAmount,
        contingencyPositiveAdjustedAmount,
        nonContingencyPositiveTransactions,
      } = getAdjustmentData(adjustment, lineItemData);

      const { knownBucketAmounts, untrackedContingency } = acc;

      // Any positive contingency adjustment will count as "untracked"
      if (contingencyPositiveAdjustedAmount > 0) {
        return {
          ...acc,
          untrackedContingency: add(
            untrackedContingency,
            contingencyPositiveAdjustedAmount
          ),
        };
      }

      // no net contingency change, no movement
      if (contingencyNegativeAdjustedAmount === 0) {
        return acc;
      }

      // If the only adjustment is a contingency adjustment,
      // it will count as "untracked"
      if (
        nonContingencyNegativeTransactionAmount === 0 &&
        nonContingencyPositiveTransactions.length === 0
      ) {
        return {
          ...acc,
          untrackedContingency: add(
            untrackedContingency,
            contingencyNegativeAdjustedAmount
          ),
        };
      }

      // If there is only one non-contingency adjustment
      if (
        nonContingencyNegativeTransactionAmount === 0 &&
        nonContingencyPositiveTransactions.length === 1
      ) {
        const { lineItemId } = nonContingencyPositiveTransactions[0];
        const currentKnownAmount = knownBucketAmounts[lineItemId] || 0;

        // If contingency adjustments are less than or equal to the single adjustment,
        // we will allocate the full amount of the contingency to the adjustment,
        // there will be no “untracked” amount
        if (
          Math.abs(contingencyNegativeAdjustedAmount) <=
          nonContingencyPositiveTransactionAmount
        ) {
          return {
            ...acc,
            knownBucketAmounts: {
              ...knownBucketAmounts,
              [lineItemId]: add(
                currentKnownAmount,
                Math.abs(contingencyNegativeAdjustedAmount)
              ),
            },
          };
        }

        // If contingency adjustments are more than the single adjustment,
        // we will allocate the full non-contingency adjustment,
        // the remaining contingency will be “untracked”
        return {
          ...acc,
          knownBucketAmounts: {
            ...knownBucketAmounts,
            [lineItemId]: add(
              currentKnownAmount,
              nonContingencyPositiveTransactionAmount
            ),
          },
          untrackedContingency: add(
            add(
              contingencyNegativeAdjustedAmount,
              nonContingencyPositiveTransactionAmount
            ),
            untrackedContingency
          ),
        };
      }

      // If all non-contingency adjustments are positive
      if (nonContingencyNegativeTransactionAmount === 0) {
        const updatedKnownBucketAmounts = nonContingencyPositiveTransactions.reduce(
          (knownBuckets, { lineItemId, amount }) => {
            const currentKnownAmount = knownBuckets[lineItemId] || 0;
            return {
              ...knownBuckets,
              [lineItemId]: add(currentKnownAmount, amount),
            };
          },
          knownBucketAmounts
        );

        // If contingency adjustments are less than or equal to the single adjustment,
        // we will allocate the full amount of the contingency to the adjustment,
        // there will be no “untracked” amount
        if (
          Math.abs(contingencyNegativeAdjustedAmount) <=
          nonContingencyPositiveTransactionAmount
        ) {
          return {
            ...acc,
            knownBucketAmounts: updatedKnownBucketAmounts,
          };
        }

        // If contingency adjustments are more than the single adjustment,
        // we will allocate the full non-contingency adjustment,
        // the remaining contingency will be “untracked”
        return {
          ...acc,
          knownBucketAmounts: updatedKnownBucketAmounts,
          untrackedContingency: add(
            add(
              contingencyNegativeAdjustedAmount,
              nonContingencyPositiveTransactionAmount
            ),
            untrackedContingency
          ),
        };
      }

      // If there are non-contingency adjustments that are negative
      // AND only ONE positive adjustment
      if (
        nonContingencyNegativeTransactionAmount < 0 &&
        nonContingencyPositiveTransactions.length === 1
      ) {
        // If the sum of the contingency adjustments and all other negative adjustments is less than or equal to the positive adjustment,
        // that contingency will be tracked
        const sumOfAllNegativeAdjustments = add(
          Math.abs(contingencyNegativeAdjustedAmount),
          Math.abs(nonContingencyNegativeTransactionAmount)
        );
        if (
          sumOfAllNegativeAdjustments <= nonContingencyPositiveTransactionAmount
        ) {
          const { lineItemId } = nonContingencyPositiveTransactions[0];
          const currentKnownAmount = knownBucketAmounts[lineItemId] || 0;
          return {
            ...acc,
            knownBucketAmounts: {
              ...knownBucketAmounts,
              [lineItemId]: add(
                currentKnownAmount,
                Math.abs(contingencyNegativeAdjustedAmount)
              ),
            },
          };
        }

        // If the sum of the contingency adjustment and all other negative adjustments is greater than the positive adjustment,
        // the full amount of contingency will be “untracked”
        return {
          ...acc,
          untrackedContingency: add(
            untrackedContingency,
            contingencyNegativeAdjustedAmount
          ),
        };
      }

      // If there are non-contingency adjustments that are negative
      // AND there are multiple positive adjustments
      if (
        nonContingencyNegativeTransactionAmount < 0 &&
        nonContingencyPositiveTransactions.length > 0
      ) {
        // Contingency adjustment will always count as “untracked”
        return {
          ...acc,
          untrackedContingency: add(
            untrackedContingency,
            contingencyNegativeAdjustedAmount
          ),
        };
      }

      return acc;
    },
    {
      knownBucketAmounts: {},
      untrackedContingency: 0,
    }
  );
}

export function contingencySegmentData({ lineItems, budgetAdjustments }) {
  const {
    lineItemData,
    drawnContingencyAmount,
    contingencyBalanceToFund,
    contingencyOriginalBudgetAmount,
  } = getLineItemData(lineItems);

  // 'known buckets' are from adjustments where the destination of contingency funds is clear
  // "untracked" is a catch-all for movements of contingency funds where the destination is not clear
  const { knownBucketAmounts, untrackedContingency } = prepareBucketAmounts(
    budgetAdjustments,
    lineItemData
  );

  const knownBuckets = map(knownBucketAmounts, (amount, lineItemId) => ({
    name: lineItemData[lineItemId].name,
    value: amount,
  })).map((bucket, index) => ({
    ...bucket,
    color: getPieChartColor(index),
  }));

  const drawnBucket =
    drawnContingencyAmount > 0
      ? {
          name: "Contingency Drawn",
          value: drawnContingencyAmount,
          color: getPieChartColor(knownBuckets.length),
        }
      : null;

  const remainingContingencyBucket = {
    name: "Contingency Remaining",
    value: contingencyBalanceToFund,
    color: "#d8dae5",
  };

  const allBuckets = reject(
    [...knownBuckets, drawnBucket, remainingContingencyBucket],
    (bucket) => isNull(bucket)
  );

  const totalContingency = add(
    contingencyOriginalBudgetAmount,
    untrackedContingency
  );

  const percentUsed = divide(
    subtract(totalContingency, contingencyBalanceToFund),
    totalContingency
  );

  return {
    allContingencySegments: allBuckets,
    totalContingency,
    untrackedContingency,
    originalContingency: contingencyOriginalBudgetAmount,
    percentUsed,
  };
}
