import { useContext, Fragment } from "react";
import { css } from "glamor";
import { HelpIcon } from "evergreen-ui";
import { Pane, Paragraph, Text, Tooltip } from "components/materials";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { divide, subtract } from "helpers/math";
import { formatCurrency } from "helpers/formatCurrency";
import formatPercent from "helpers/formatPercent";
import { contingencySegmentData } from "components/containers/ProjectDashboard/ContingencyUsage/chartHelpers";
import { VictoryTheme, VictoryContainer, VictoryPie } from "victory";
import { BRAND_COLORS } from "helpers/colors";

export function ContingencyUsage({ project }) {
  return (
    <Pane display="flex" paddingX={majorScale(4)} height="100%">
      <ProjectProgress project={project} />
      <ContingencyProgress project={project} />
    </Pane>
  );
}

function ProjectProgress({ project }) {
  const theme = useContext(ThemeContext);
  const projectBudgetRemaining = subtract(
    project.amount,
    project.inProgressAmount
  );
  const percent = divide(project.inProgressAmount, project.amount);
  const values = [
    { value: project.inProgressAmount, color: BRAND_COLORS.LIGHT_PRIMARY },
    { value: projectBudgetRemaining, color: "#d8dae5" },
  ];

  return (
    <Pane height="100%" width="40%" display="flex" alignItems="center">
      <Pane flex={1} textAlign="center">
        <Pane marginBottom={majorScale(1)}>
          <Paragraph fontWeight={theme.fontWeights.DEMI}>
            {formatCurrency(project.inProgressAmount)}
          </Paragraph>
          <Paragraph>Amount Requested</Paragraph>
        </Pane>
        <Pane marginBottom={majorScale(1)}>
          <Paragraph fontWeight={theme.fontWeights.DEMI}>
            {formatCurrency(projectBudgetRemaining)}
          </Paragraph>
          <Paragraph>Amount Remaining</Paragraph>
        </Pane>
        <Pane marginBottom={majorScale(1)}>
          <Paragraph fontWeight={theme.fontWeights.DEMI}>
            {formatCurrency(project.amount)}
          </Paragraph>
          <Paragraph>Current Budget</Paragraph>
        </Pane>
      </Pane>
      <Pane flex={1} position="relative">
        <DoughnutChart
          values={values}
          percent={percent}
          label="Percent Requested"
          theme={theme}
        />
      </Pane>
    </Pane>
  );
}

function ContingencyProgress({ project }) {
  const theme = useContext(ThemeContext);

  const {
    allContingencySegments,
    originalContingency,
    percentUsed,
    untrackedContingency,
  } = contingencySegmentData({
    budgetAdjustments: project.budgetAdjustments,
    lineItems: project.lineItems,
  });

  const nameClassName = css({
    textAlign: "start",
  });

  const nameOverflowProps = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };

  const currencyClassName = css({
    textAlign: "end",
  });

  const bottomRowBorder = css({
    borderTop: `2px solid ${BRAND_COLORS.PRIMARY}`,
  });

  const tableClassName = css({
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
  });

  return (
    <Pane height="100%" width="60%" display="flex" alignItems="center">
      <Pane width="45%" position="relative">
        <DoughnutChart
          values={allContingencySegments}
          percent={percentUsed}
          label="Contingency Used"
          theme={theme}
        />
      </Pane>
      <Pane
        flex={1}
        textAlign="center"
        paddingTop={majorScale(2)}
        marginRight={majorScale(2)}
        height="100%"
        width="55%"
        overflowY="auto"
      >
        <table className={tableClassName}>
          {allContingencySegments.map(({ name, value, color, tooltip }) => (
            <tr key="name">
              <td className={nameClassName}>
                <Pane display="flex" alignItems="center" width="240px">
                  <Paragraph {...nameOverflowProps}>{name}</Paragraph>
                  {tooltip && (
                    <Tooltip content={tooltip}>
                      <HelpIcon
                        color="default"
                        marginLeft={minorScale(1)}
                        size={minorScale(3)}
                      />
                    </Tooltip>
                  )}
                </Pane>
              </td>
              <td className={currencyClassName}>
                <Text fontWeight={theme.fontWeights.DEMI}>
                  {formatCurrency(value)}
                </Text>
              </td>
              <td aria-label="progress">
                <Pane
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Pane height={16} width={16} background={color} />
                </Pane>
              </td>
            </tr>
          ))}
          <tr className={bottomRowBorder}>
            <td className={nameClassName}>
              <Paragraph {...nameOverflowProps}>Original Contingency</Paragraph>
            </td>
            <td className={currencyClassName}>
              <Text fontWeight={theme.fontWeights.DEMI}>
                {formatCurrency(originalContingency)}
              </Text>
            </td>
          </tr>
          <tr>
            <td className={nameClassName}>
              <Pane display="flex">
                <Paragraph {...nameOverflowProps}>
                  Non Allocated Adjustments
                </Paragraph>
                <Pane
                  marginLeft={minorScale(1)}
                  display="flex"
                  alignItems="center"
                >
                  <Tooltip content="Adjustments to contingency line items that are not reallocated to another line item.">
                    <HelpIcon color="default" size={minorScale(3)} />
                  </Tooltip>
                </Pane>
              </Pane>
            </td>
            <td className={currencyClassName}>
              <Text fontWeight={theme.fontWeights.DEMI}>
                {formatCurrency(untrackedContingency)}
              </Text>
            </td>
          </tr>
        </table>
      </Pane>
    </Pane>
  );
}

function DoughnutChart({ values, percent, label, theme }) {
  const victoryTheme = VictoryTheme.grayscale;
  const style = { height: 260, width: "100%", minWidth: 260 };
  const data = values.map(({ value }) => ({ y: value }));
  const colorScale = values.map(({ color }) => color);

  return (
    <Fragment>
      <div style={style}>
        <VictoryContainer
          height={victoryTheme.pie.height}
          width={victoryTheme.pie.width}
        >
          <VictoryPie
            standalone={false}
            data={data}
            colorScale={colorScale}
            padding={20}
            innerRadius={130}
            labels={() => null}
            animate={false}
            startAngle={-150}
            endAngle={150}
          />
        </VictoryContainer>
      </div>
      <Pane
        position="absolute"
        top="50%"
        left="50%"
        textAlign="center"
        transform="translate(-50%, -60%)"
      >
        <Paragraph size={400} fontWeight={theme.fontWeights.DEMI}>
          {formatPercent(percent)}
        </Paragraph>
        <Paragraph marginTop={minorScale(1)}>{label}</Paragraph>
      </Pane>
    </Fragment>
  );
}
