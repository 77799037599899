import { useState } from "react";
import {
  Pane,
  ConfigureButtons,
  Text,
  Tablist,
  Tab,
} from "components/materials";
import { has, set, values } from "lodash";
import { majorScale, minorScale } from "helpers/utilities";
import {
  getDateInterval,
  DATE_RANGE,
  isWithinInterval,
  parseDate,
} from "helpers/dateHelpers";
import { formatCurrency } from "helpers/formatCurrency";
import { CardHeading } from "./CardHeading";
import { BlankSlate, BlankSlateType } from "./BlankSlate";
import { Table } from "./Table";
import { toBase64 } from "../../materials/FastDataTable";
import { CardLinkButton } from "./CardLinkButton";

export const USER_ACTIVITY_CONFIGURATION_SETTINGS = {
  i: "userActivity",
  x: 3,
  y: 4,
  w: 1,
  h: 1,
  disabled: false,
};

const VIEWS = {
  CURRENT_MONTH: {
    key: "CURRENT_MONTH",
    name: "Current Month",
    matchesDateInterval: (fundedDate) => {
      return isWithinInterval(
        parseDate(fundedDate),
        getDateInterval(DATE_RANGE.MonthToDate)
      );
    },
  },
  PAST_MONTH: {
    key: "PAST_MONTH",
    name: "Past Month",
    matchesDateInterval: (fundedDate) => {
      return isWithinInterval(
        parseDate(fundedDate),
        getDateInterval(DATE_RANGE.PreviousXMonths, 1)
      );
    },
  },
  ALL_TIME: {
    key: "ALL_TIME",
    name: "All Time",
    matchesDateInterval: () => true,
  },
};

export function UserActivity({
  cards,
  isConfigurable,
  isDisabled,
  name,
  projects,
  setCards,
}) {
  const [selectedView, setSelectedView] = useState(VIEWS.CURRENT_MONTH);

  const usersWithFundedDrawCount = projects.reduce((acc, { draws }) => {
    const drawsInRangeFundedByAUser = draws.filter(
      ({ fundedByUser, fundedDate }) =>
        fundedByUser !== null &&
        fundedDate !== null &&
        VIEWS[selectedView.key].matchesDateInterval(fundedDate)
    );

    drawsInRangeFundedByAUser.forEach((draw) => {
      const { fundedByUser } = draw;

      if (has(acc, fundedByUser.id)) {
        acc[fundedByUser.id].drawsFunded += 1;
        acc[fundedByUser.id].totalAmountRequested += draw.requestedAmount;
      } else {
        set(acc, fundedByUser.id, {
          drawsFunded: 1,
          userName: `${fundedByUser.firstName} ${fundedByUser.lastName}`,
          totalAmountRequested: draw.requestedAmount,
        });
      }
    });
    return acc;
  }, {});

  const columns = [
    {
      name: "User",
      sortKey: "userName",
      valueKey: "userName",
      width: "40%",
    },
    {
      name: "Draws Funded",
      sortKey: "drawsFunded",
      valueKey: "drawsFunded",
      width: "30%",
    },
    {
      name: "Total Funded",
      renderCell: (row) => (
        <Text>{formatCurrency(row.totalAmountRequested)}</Text>
      ),
      sortKey: "totalAmountRequested",
      width: "30%",
    },
  ];

  const tableData = values(usersWithFundedDrawCount).sort(
    (userA, userB) => userB.drawsFunded - userA.drawsFunded
  );

  // Edit to check if any draws match criteria when implemented
  const showBlankSlate = projects.length === 0;

  const drawsProcessingViewConfig = toBase64({
    columnConfig: [
      "projectName",
      "drawName",
      "currentAmountRequested",
      "drawSentDate",
      "dateFunded",
      "inspectionUploadedDate",
      "titleReportUploadedDate",
      "approvers",
    ],
    filterConfig: [],
    groupConfig: {},
    sortConfig: {},
  });

  return (
    <Pane height="100%" width="100%" padding={majorScale(2)}>
      <Pane
        display="flex"
        marginBottom={minorScale(3)}
        justifyContent="space-between"
      >
        <CardHeading disabled={isDisabled} text="User Activity" />
        {isConfigurable && (
          <ConfigureButtons
            isDisabled={isDisabled}
            cards={cards}
            setCards={setCards}
            name={name}
          />
        )}
        {!isConfigurable && (
          <CardLinkButton
            href={`/reports/draws?table=${drawsProcessingViewConfig}`}
          />
        )}
      </Pane>
      <Pane>
        <Tablist marginBottom={majorScale(2)}>
          {values(VIEWS).map((view) => (
            <Tab
              key={view.key}
              isSelected={selectedView === view}
              onSelect={() => setSelectedView(view)}
            >
              {view.name}
            </Tab>
          ))}
        </Tablist>
      </Pane>
      <Pane>
        <Pane maxHeight={300} overflowY="scroll">
          <Table
            columns={columns}
            defaultSortDirection="desc"
            defaultSortKey="totalAmountRequested"
            rows={tableData}
            cellBorderY={false}
            hasFooter={false}
          />
        </Pane>
      </Pane>
      {showBlankSlate && <BlankSlate type={BlankSlateType.Draws} />}
    </Pane>
  );
}
