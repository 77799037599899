import { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { useHiddenViewerOnMount } from "helpers/behaviors/InternetExplorerContext";
import { Pane } from "evergreen-ui";
import { majorScale } from "helpers/utilities";
import { Button, Dialog, HiddenFocusElement } from "components/materials";

export function Modal({
  children,
  hideViewer = false,
  onClose,
  open,
  size = null,
  trigger = null,
  withoutAutofocus = false,
  shouldCloseOnOverlayClick = true,
  hasFooter = false,
  ...props
}) {
  useHiddenViewerOnMount(hideViewer && open);

  const sizes = {
    small: {
      minWidth: "45%",
    },
    medium: {
      width: "60%",
    },
    large: {
      width: "75%",
    },
    fullscreen: {
      topOffset: majorScale(2),
      sideOffset: majorScale(2),
      width: "100%",
    },
  };

  const sizeProps = sizes[size] || {};

  const [keyDownIn, setKeyDownIn] = useState(false);
  const [shouldClose, setShouldClose] = useState(false);

  // there must have been a reason for this when it was written (some weird bug?)
  // by default, the Dialog will close on overlay click
  // however, we always pass shouldCloseOnOverlayClick={false} to disable the default behavior defined in Evergreen,
  // and instead achieve that same default behavior with the handlers defined below
  const overlayProps =
    shouldCloseOnOverlayClick === false
      ? {}
      : {
          onMouseDown: (e) => setKeyDownIn(e.target === e.currentTarget),
          onMouseUp: (e) =>
            setShouldClose(e.target === e.currentTarget && keyDownIn),
        };

  return (
    <Fragment>
      {trigger}
      <Dialog
        shouldAutoFocus={false}
        isShown={open && !shouldClose}
        onCloseComplete={() => {
          setShouldClose(false);
          onClose();
        }}
        preventBodyScrolling
        shouldCloseOnOverlayClick={false}
        overlayProps={overlayProps}
        {...(hasFooter
          ? { footer: ({ close }) => <Footer close={close} {...props} /> }
          : { hasFooter: false })}
        {...sizeProps}
        {...props}
      >
        {(dialogProps) => {
          return (
            <Fragment>
              {withoutAutofocus && <HiddenFocusElement />}
              {typeof children === "function"
                ? children(dialogProps)
                : children}
            </Fragment>
          );
        }}
      </Dialog>
    </Fragment>
  );
}

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  hasCancel: PropTypes.bool,
  hasFooter: PropTypes.bool,
  hideViewer: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  size: PropTypes.oneOf(["fullscreen", "large", "medium", "small"]),
  title: PropTypes.node,
  trigger: PropTypes.node,
  withoutAutofocus: PropTypes.bool,
  cancelLabel: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  confirmLabel: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

Modal.defaultProps = {
  hasCancel: true,
  hasFooter: false,
  hideViewer: false,
  onClose: () => {},
  trigger: null,
  cancelLabel: "Cancel",
  confirmLabel: "Confirm",
};

// TODO: remove dot notation
Modal.Content = Content;
Modal.Actions = Actions;

function Footer({
  close,
  hasCancel,
  onCancel,
  onConfirm,
  cancelLabel,
  confirmLabel,
  isConfirmDisabled,
  isConfirmLoading,
}) {
  return (
    <Pane display="flex" justifyContent="flex-end">
      <Pane>
        {hasCancel && (
          <Button
            tabIndex={0}
            onClick={() => {
              onCancel && onCancel(close);
              close();
            }}
          >
            {cancelLabel}
          </Button>
        )}
        <Button
          tabIndex={0}
          marginLeft={majorScale(2)}
          appearance="primary"
          isLoading={isConfirmLoading}
          disabled={isConfirmDisabled}
          onClick={() => onConfirm(close)}
        >
          {confirmLabel}
        </Button>
      </Pane>
    </Pane>
  );
}

function Actions({ children, ...props }) {
  return (
    <Pane paddingY={majorScale(2)} {...props}>
      {children}
    </Pane>
  );
}

function Content({ children, ...props }) {
  return <Pane {...props}>{children}</Pane>;
}
