import { useContext, useMemo, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { useFeatureFlags } from "FeatureFlags";
import { DocumentTable, PendingUploadsBanner } from "components/templates";
import { PendingUploadsBanner as NewPendingUploadsBanner } from "features/uploads";
import { Button, Loadable } from "components/materials";
import { UploadsViewerContext, UserContext } from "helpers/behaviors";
import { formatFastDocuments } from "helpers/documentHelpers";
import { PERMISSION_ACTION } from "helpers/enums";
import {
  ASSIGNED_TO_DOC_TABLE_FRAGMENT,
  DOC_REVIEWERS_DOC_TABLE_FRAGMENT,
  FAST_DOCUMENT_FRAGMENT,
  UPLOAD_DOC_TABLE_FRAGMENT,
  MOVE_TO_DRAW_DOC_TABLE_FRAGMENT,
} from "helpers/fragments";
import { minorScale } from "helpers/utilities";
import t from "helpers/translate";
import { cloneDeep, findIndex, get, set } from "lodash";
import { Document } from "./Document";

const FAST_QUERY = gql`
  query DrawDocumentsFastQuery($drawId: String!, $projectId: String!) {
    project(id: $projectId) {
      id
      ...AssignedToDocTableFragment
      ...DocReviewersDocTableFragment
      ...MoveToDrawDocTableFragment
      draw(id: $drawId) {
        id
        fastDocuments {
          id
          ...FastDocumentFragment
        }
        uploads(toBeSplit: true) {
          ...UploadDocTableFragment
        }
      }
    }
  }
  ${ASSIGNED_TO_DOC_TABLE_FRAGMENT}
  ${DOC_REVIEWERS_DOC_TABLE_FRAGMENT}
  ${FAST_DOCUMENT_FRAGMENT}
  ${MOVE_TO_DRAW_DOC_TABLE_FRAGMENT}
  ${UPLOAD_DOC_TABLE_FRAGMENT}
`;

const FAST_DOCUMENT_UPDATED_SUBSCRIPTION = gql`
  subscription onDocumentUpdated($projectId: String!) {
    projectFastDocumentUpdated(projectId: $projectId) {
      id
      ...FastDocumentFragment
    }
  }
  ${FAST_DOCUMENT_FRAGMENT}
`;

const FAST_DOCUMENT_REMOVED_SUBSCRIPTION = gql`
  subscription onDocumentRemoved($projectId: String!) {
    projectFastDocumentRemoved(projectId: $projectId)
  }
`;

const ISSUES_SUBSCRIPTION = gql`
  subscription drawIssuesUpdated($drawId: String!) {
    drawIssuesUpdated(drawId: $drawId) {
      id
      documents {
        id
        issues {
          id
          name
          severity
        }
      }
    }
  }
`;

const UPLOAD_SUBSCRIPTION = gql`
  subscription onUploadUpdated($projectId: String!) {
    projectUploadUpdated(projectId: $projectId) {
      ...UploadDocTableFragment
    }
  }
  ${UPLOAD_DOC_TABLE_FRAGMENT}
`;

export function DrawDocumentsPage({ history, match }) {
  const featureFlags = useFeatureFlags();
  const useNewUploads = featureFlags.isEnabled("use-upload-refactor");

  const { documentId, drawId, projectId } = match.params;

  const { setOpenUploads } = useContext(UploadsViewerContext);
  const { analyticsContext, hasPermission, organizationId } = useContext(
    UserContext
  );

  const { data, loading, subscribeToMore } = useQuery(FAST_QUERY, {
    context: {
      ...analyticsContext,
      analyticsMessage: "Draw Documents Page",
    },
    variables: { drawId, projectId },
    key: drawId,
  });

  const refetch = [{ query: FAST_QUERY, variables: { drawId, projectId } }];

  const rightControls = useMemo(() => {
    return [
      hasPermission(PERMISSION_ACTION.DOWNLOAD_DOCUMENT) && (
        <Button
          key="viewUploads"
          marginLeft={minorScale(3)}
          onClick={() => setOpenUploads(null, drawId, projectId)}
          purpose="documents uploads open"
        >
          View Uploads
        </Button>
      ),
    ];
  }, [drawId, hasPermission, projectId, setOpenUploads]);

  useEffect(() => {
    return subscribeToMore({
      document: ISSUES_SUBSCRIPTION,
      variables: { drawId },
    });
  }, [drawId, subscribeToMore]);

  useEffect(() => {
    return subscribeToMore({
      document: FAST_DOCUMENT_UPDATED_SUBSCRIPTION,
      variables: { projectId },
      updateQuery: (prev, { subscriptionData }) => {
        const updatedDocument = get(
          subscriptionData,
          "data.projectFastDocumentUpdated",
          {}
        );

        if (
          !prev ||
          !updatedDocument.id ||
          updatedDocument.id === documentId ||
          get(updatedDocument, "drawId") !== drawId
        )
          return prev;
        const previousDocuments = [
          ...get(prev, "project.draw.fastDocuments", []),
        ];
        const index = findIndex(previousDocuments, ["id", updatedDocument.id]);

        if (index >= 0) {
          previousDocuments[index] = {
            ...get(prev, `project.draw.fastDocuments[${index}]`),
            ...updatedDocument,
          };

          return {
            ...prev,
            project: {
              ...prev.project,
              draw: {
                ...prev.project.draw,
                fastDocuments: previousDocuments,
              },
            },
          };
        }
        return {
          ...prev,
          project: {
            ...get(prev, "project", {}),
            draw: {
              ...get(prev, "project.draw", {}),
              fastDocuments: [...previousDocuments, updatedDocument],
            },
          },
        };
      },
    });
  }, [documentId, projectId, drawId, subscribeToMore]);

  useEffect(() => {
    return subscribeToMore({
      document: FAST_DOCUMENT_REMOVED_SUBSCRIPTION,
      variables: { projectId },
      updateQuery: (prev, { subscriptionData }) => {
        const removedDocumentId = get(
          subscriptionData,
          "data.projectFastDocumentRemoved"
        );

        if (!removedDocumentId) return prev;

        const previousDocuments = [
          ...get(prev, "project.draw.fastDocuments", []),
        ];

        const newDocuments = previousDocuments.filter(
          ({ id }) => id !== removedDocumentId
        );

        return set(cloneDeep(prev), "project.draw.fastDocuments", newDocuments);
      },
    });
  }, [projectId, subscribeToMore]);

  useEffect(() => {
    return subscribeToMore({
      document: UPLOAD_SUBSCRIPTION,
      variables: { projectId },
      updateQuery: (prev, { subscriptionData }) => {
        const updatedUpload = get(
          subscriptionData,
          "data.projectUploadUpdated",
          {}
        );
        if (!updatedUpload.id || get(updatedUpload, "draw.id") !== drawId)
          return prev;

        const previousUploads = [...get(prev, "project.draw.uploads", [])];
        const foundIndex = findIndex(previousUploads, ["id", updatedUpload.id]);

        //  upload belongs in the list
        if (updatedUpload.toBeSplit) {
          //  if it's already there, updated it
          if (foundIndex >= 0) {
            previousUploads[foundIndex] = {
              ...get(prev, `project.draw.uploads[${foundIndex}]`),
              ...updatedUpload,
            };

            return {
              ...prev,
              project: {
                ...prev.project,
                draw: {
                  ...prev.project.draw,
                  uploads: previousUploads,
                },
              },
            };
          }
          // otherwise, add it
          return {
            ...prev,
            project: {
              ...prev.project,
              draw: {
                ...prev.project.draw,
                uploads: [updatedUpload, ...previousUploads],
              },
            },
          };
        }

        // upload does not belong in the list and was found - remove it
        if (foundIndex >= 0) {
          return {
            ...prev,
            project: {
              ...get(prev, "project", {}),
              draw: {
                ...get(prev, "project.draw", {}),
                uploads: previousUploads.filter(
                  (_upload, index) => index !== foundIndex
                ),
              },
            },
          };
        }

        return prev;
      },
    });
  }, [projectId, drawId, subscribeToMore]);

  const draws = get(data, "project.draws", []);

  const tableDocuments = formatFastDocuments(
    get(data, "project.draw.fastDocuments", []).filter(
      ({ parentToBeSplit }) => !parentToBeSplit
    )
  );
  const hasIssues = tableDocuments.some(({ issues }) => issues.length > 0);

  const defaultColumns = ["document"]
    .concat(
      hasPermission(PERMISSION_ACTION.RULES_REDESIGN_CLERICAL) && hasIssues
        ? ["documentIssues"]
        : []
    )
    .concat([
      "vendor",
      "currentAmountRequested",
      "uploadedAt",
      "pages",
      "originalFile",
      "status",
      "backup",
    ])
    .concat(
      hasPermission(PERMISSION_ACTION.ASSIGN_DOCUMENTS) ? ["assignedTo"] : []
    )
    .concat(
      hasPermission(PERMISSION_ACTION.TIERED_DOCUMENT_REVIEWERS)
        ? ["approval"]
        : []
    );

  const defaultGroup = { columnId: "type" };

  if (loading)
    return (
      <Loadable
        loading
        spinnerText={t("loadingText.documentLoading")}
        textDelay={3000}
        timeout={180000}
      />
    );

  return (
    <Fragment>
      {useNewUploads && (
        <NewPendingUploadsBanner
          drawId={drawId}
          projectId={projectId}
          uploads={get(data, "project.draw.uploads", [])}
        />
      )}
      {!useNewUploads && (
        <PendingUploadsBanner
          drawId={drawId}
          projectId={projectId}
          uploads={get(data, "project.draw.uploads", [])}
        />
      )}
      <DocumentTable
        defaultColumns={defaultColumns}
        defaultGroup={defaultGroup}
        documents={tableDocuments}
        documentReviewersByProject={{
          [projectId]: data.project.documentReviewers,
        }}
        drawId={drawId}
        draws={draws}
        history={history}
        match={match}
        organizationId={organizationId}
        projectId={projectId}
        rightControls={rightControls}
        suggestedDocumentAssignees={data.project.suggestedDocumentAssignees}
        tableName="DrawDocumentTable"
        users={get(data, "project.users")}
      />
      {documentId && (
        <Document
          defaultGroup={defaultGroup}
          documents={tableDocuments}
          history={history}
          match={match}
          refetch={refetch}
        />
      )}
    </Fragment>
  );
}

DrawDocumentsPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};
