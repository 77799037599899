import { useMemo, Fragment, useContext } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { DocumentTable } from "components/templates";
import { Loadable } from "components/materials";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import {
  AGREEMENT_DOC_TABLE_FRAGMENT,
  DOCUMENT_TABLE_FRAGMENT,
  DRAW_SUMMARY_DOC_TABLE_FRAGMENT,
  INVOICE_DOC_TABLE_FRAGMENT,
  LIEN_RELEASE_DOC_TABLE_FRAGMENT,
  PAY_APPLICATION_DOC_TABLE_FRAGMENT,
} from "helpers/fragments";
import { get } from "lodash";
import { Document } from "./Document";

const QUERY = gql`
  query SubmissionDocumentsPageQuery($submissionId: String!) {
    submission(submissionId: $submissionId) {
      id
      sourceDraw {
        id
        documents {
          ...DocumentTableFragment
          ...DrawSummaryDocTableFragment
          ...InvoiceDocTableFragment
          ...LienReleaseDocTableFragment
          ...PayApplicationDocTableFragment
          ...AgreementDocTableFragment
        }
      }
    }
  }
  ${DOCUMENT_TABLE_FRAGMENT}
  ${DRAW_SUMMARY_DOC_TABLE_FRAGMENT}
  ${INVOICE_DOC_TABLE_FRAGMENT}
  ${LIEN_RELEASE_DOC_TABLE_FRAGMENT}
  ${PAY_APPLICATION_DOC_TABLE_FRAGMENT}
  ${AGREEMENT_DOC_TABLE_FRAGMENT}
`;

export function SubmissionDocumentsPage({ history, match }) {
  const { hasPermission, organizationId } = useContext(UserContext);
  const { documentId, submissionId } = match.params;
  const { data, loading } = useQuery(QUERY, {
    variables: { submissionId },
  });

  const documents = get(data, "submission.sourceDraw.documents", []);
  const tableDocuments = useMemo(
    () => documents.filter(({ upload }) => !upload.toBeSplit),
    [documents]
  );

  const defaultColumns = [
    "document",
    "vendor",
    "currentAmountRequested",
    "uploadedAt",
    "pages",
    "originalFile",
    "status",
    "backup",
  ]
    .concat(
      hasPermission(PERMISSION_ACTION.ASSIGN_DOCUMENTS) ? ["assignedTo"] : []
    )
    .concat(
      hasPermission(PERMISSION_ACTION.TIERED_DOCUMENT_REVIEWERS)
        ? ["approval"]
        : []
    );
  const defaultGroup = { columnId: "type" };

  if (loading) return <Loadable loading />;

  return (
    <Fragment>
      <DocumentTable
        defaultColumns={defaultColumns}
        defaultGroup={defaultGroup}
        documents={tableDocuments}
        history={history}
        match={match}
        organization={organizationId}
        tableName="SubmissionDocumentTable"
      />
      {documentId && (
        <Document documents={tableDocuments} history={history} match={match} />
      )}
    </Fragment>
  );
}
SubmissionDocumentsPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};
