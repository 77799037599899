import { useState, useContext, Fragment } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Confirm, ListItem, Text, UnorderedList } from "components/materials";
import t from "helpers/translate";
import { Frozen, UserContext } from "helpers/behaviors";
import { updateFastDocumentCache } from "helpers/fastDocumentCache";
import {
  getCalculatedFileName,
  isAgreementWithAdjustments,
  isSummaryWithAdjustments,
  aggregateDocumentApprovalsData,
} from "helpers/documentHelpers";
import { majorScale, toaster } from "helpers/utilities";
import { get, includes, values } from "lodash";
import { AGREEMENT_TYPE, INVOICE_TYPE, PERMISSION_ACTION } from "helpers/enums";
import { AlsoDeleteAgreementModal } from "components/templates/DocumentReview/AlsoDeleteAgreementModal";
import {
  PROJECT_QUERY as ASSIGN_TO_PROJECT_QUERY,
  DRAW_QUERY as ASSIGN_TO_DRAW_QUERY,
} from "./NotifyDocumentAssignees";

function noOp() {
  return null;
}

const ASSIGN_USER_TO_DOCUMENTS = gql`
  mutation AssignUserToDocuments($documentIds: [String]!, $userId: String) {
    assignUserToDocuments(documentIds: $documentIds, userId: $userId) {
      id
      assignedUser {
        id
        fullName
      }
    }
  }
`;

const APPROVE_DOCUMENTS = gql`
  mutation ApproveDocuments($documentIds: [String]!) {
    approveDocuments(documentIds: $documentIds) {
      id
      assignedUser {
        id
      }
      recentApprovalReviews {
        id
        userId
        userName
        reviewType
        insertedAt
      }
    }
  }
`;

const IGNORE_DOCUMENTS = gql`
  mutation IgnoreDocuments($documentIds: [String]!) {
    ignoreDocuments(documentIds: $documentIds) {
      id
      state
    }
  }
`;

export const MARK_DOCUMENT_PAID = gql`
  mutation MarkDocumentPaid(
    $documentId: String!
    $amountPaid: Currency!
    $datePaid: Date!
  ) {
    markDocumentPaid(
      documentId: $documentId
      amountPaid: $amountPaid
      datePaid: $datePaid
    ) {
      id
      isPaid
    }
  }
`;

export const MARK_DOCUMENT_UNPAID = gql`
  mutation MarkDocumentUnpaid($documentId: String!) {
    markDocumentUnpaid(documentId: $documentId) {
      id
      isPaid
    }
  }
`;

const REMOVE_DOCUMENTS = gql`
  mutation DeleteDocuments(
    $alsoDeleteAgreements: Boolean
    $documentIds: [String]!
  ) {
    deleteDocuments(
      alsoDeleteAgreements: $alsoDeleteAgreements
      documentIds: $documentIds
    ) {
      status
    }
  }
`;

const MARK_BACKUP = gql`
  mutation MarkBackup($documentIds: [String]!) {
    markDocumentsAsBackup(documentIds: $documentIds) {
      id
      isBackup
    }
  }
`;

const MOVE_DOCUMENTS_TO_DRAW = gql`
  mutation MoveDocumentsToDraw($documentIds: [String]!, $drawId: String!) {
    moveDocumentsToDraw(documentIds: $documentIds, drawId: $drawId) {
      id
      draw {
        id
        name
      }
    }
  }
`;

const baseUrl = process.env.REACT_APP_GRAPHQL_HOST;

const getIneligibleDocumentsContent = (
  documents,
  frozenDrawIds,
  documentReviewers
) => {
  if (!documents) return null;
  const approvedDocuments = documents.filter((document) => {
    const { approvedCount } = aggregateDocumentApprovalsData(
      document,
      documentReviewers
    );
    return approvedCount > 0;
  });

  const documentsOnFrozenDraws = documents.filter(({ draw }) =>
    includes(frozenDrawIds, draw?.id)
  );

  return (
    <Fragment>
      {approvedDocuments.length > 0 && (
        <Fragment>
          <Text>
            The following Documents have been approved and cannot be moved:
          </Text>
          <UnorderedList marginY={majorScale(2)}>
            {approvedDocuments.map((document) => (
              <ListItem key={document.id}>
                <Text>{getCalculatedFileName(document)}</Text>
              </ListItem>
            ))}
          </UnorderedList>
        </Fragment>
      )}
      {documentsOnFrozenDraws.length > 0 && (
        <Fragment>
          <Text>
            The following Documents are on Draws that have been approved,
            submitted, or funded, and cannot be moved:
          </Text>
          <UnorderedList marginY={majorScale(2)}>
            {documentsOnFrozenDraws.map((document) => (
              <ListItem key={document.id}>
                <Text>{getCalculatedFileName(document)}</Text>
              </ListItem>
            ))}
          </UnorderedList>
        </Fragment>
      )}
    </Fragment>
  );
};

// Render an "inner" component in order to get top level access to information
// provided by the Frozen and UserContext components
const DocumentActionsInner = ({
  canCreateAdjustments,
  children,
  deleteRefetch,
  documentReviewers,
  drawId,
  frozenError,
  frozenSubmit,
  frozenDrawIds,
  hasAgreementManagement,
  hasAssignedToPermission,
  hasDownloadPermission,
  hasStampDocumentsPermission,
  isFrozenError,
  projectId,
  userId,
}) => {
  const [confirmActionName, setConfirmActionName] = useState(null);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [ineligibleDocuments, setIneligibleDocuments] = useState(null);
  const [onCompleteCallback, setOnCompleteCallback] = useState(() => noOp);

  const selectedDocumentIds = selectedDocuments.map(({ id }) => id);

  const handleOpen = (actionName, callback) => (documents) => {
    setConfirmActionName(actionName);
    setSelectedDocuments(documents);
    setOnCompleteCallback(() => callback);
  };

  const handleClose = () => {
    setConfirmActionName(null);
    setSelectedDocuments([]);
  };

  const handleError = (frozenError, isFrozenError) => (error) => {
    // If the error is a frozen error, preserve the target document ids,
    // else clear the document ids out of the state
    if (!isFrozenError(error)) {
      onCompleteCallback();
    }

    frozenError(error);
  };
  const update = (apolloStore, { data }) =>
    updateFastDocumentCache(apolloStore, data);

  const [approveMutation, { loading: approveLoading }] = useMutation(
    APPROVE_DOCUMENTS,
    {
      variables: { documentIds: selectedDocumentIds },
      onCompleted: (...args) => {
        onCompleteCallback && onCompleteCallback(...args);
        toaster.notify("Document(s) approved!", {
          duration: 2.5,
        });
      },
      update,
    }
  );

  const [assignToMutation, { loading: assignLoading }] = useMutation(
    ASSIGN_USER_TO_DOCUMENTS,
    {
      variables: { documentIds: selectedDocumentIds, userId },
      onCompleted: (...args) => {
        onCompleteCallback && onCompleteCallback(...args);
        toaster.notify("Document(s) assigned!", {
          duration: 2.5,
        });
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: drawId ? ASSIGN_TO_DRAW_QUERY : ASSIGN_TO_PROJECT_QUERY,
          variables: { projectId, drawId },
        },
      ],
      update,
    }
  );

  const [moveToDrawMutation, { loading: moveLoading }] = useMutation(
    MOVE_DOCUMENTS_TO_DRAW,
    {
      onCompleted: (...args) => {
        onCompleteCallback && onCompleteCallback(...args);
        toaster.notify("Document(s) moved!", {
          duration: 2.5,
        });
      },
      ...(drawId
        ? {
            awaitRefetchQueries: true,
            refetchQueries: deleteRefetch,
          }
        : {}),
      update,
    }
  );

  const [ignoreMutation, { loading: ignoreLoading }] = useMutation(
    IGNORE_DOCUMENTS,
    {
      onCompleted: onCompleteCallback,
      onError: handleError(frozenError, isFrozenError),
    }
  );

  const [removeMutation, { loading: removeLoading }] = useMutation(
    REMOVE_DOCUMENTS,
    {
      awaitRefetchQueries: true,
      refetchQueries: deleteRefetch,
      variables: { documentIds: selectedDocumentIds },
      onCompleted: onCompleteCallback,
      onError: handleError(frozenError, isFrozenError),
    }
  );

  const [markBackupMutation, { loading: markBackupLoading }] = useMutation(
    MARK_BACKUP,
    {
      onError: handleError(frozenError, isFrozenError),
      update,
    }
  );

  const mutationForAction = (actionName) => {
    const mutationMapping = {
      onApprove: approveMutation,
      onRemove: removeMutation,
      onIgnore: ignoreMutation,
    };
    const mutate = mutationMapping[actionName] || noOp;
    return frozenSubmit(mutate);
  };

  const handleConfirm = (actionName) => (otherArgs = {}) => {
    const mutate = mutationForAction(actionName);
    mutate({ variables: { documentIds: selectedDocumentIds, ...otherArgs } });
    handleClose();
  };

  const onDownload = (docIds) => {
    window.open(`${baseUrl}/download_multiple_documents/${docIds}`);
  };

  const onDownloadStamped = (docIds) => {
    window.open(`${baseUrl}/download_multiple_stamped_documents/${docIds}`);
  };

  const onDownloadOriginal = (document) => {
    const url = get(document, "originalFile.url");
    if (url) {
      window.open(url);
    } else {
      window.open(`${baseUrl}/download_original_document/${document.id}`);
    }
  };

  const renderConfirm = () => {
    if (!confirmActionName) return null;

    const includesDrawSummaryWithAdjustments = selectedDocuments.some(
      isSummaryWithAdjustments
    );

    const includesAgreement = selectedDocuments.some(({ type }) =>
      values(AGREEMENT_TYPE).includes(type)
    );
    const agreementsWithAdjustments = selectedDocuments.filter(
      isAgreementWithAdjustments
    );
    const includesAgreementWithAdjustments =
      agreementsWithAdjustments.length > 0;

    const invoicesWithTrackedCosts = selectedDocuments.filter(
      (document) =>
        values(INVOICE_TYPE).includes(document.type) &&
        get(document, "trackedAgreementAmounts", []).length > 0
    );

    const includesInvoicesWithTrackedCosts =
      invoicesWithTrackedCosts.length > 0;

    if (confirmActionName === "onIgnore" && includesInvoicesWithTrackedCosts) {
      return (
        <Confirm
          cancelLabel="Cancel"
          confirmLabel="Continue"
          content={
            <Fragment>
              <Text>
                {t("bulk.content.confirmIgnoreInvoicesWithTrackedCosts", {
                  count: invoicesWithTrackedCosts.length,
                })}
              </Text>
              <UnorderedList marginY={majorScale(2)}>
                {invoicesWithTrackedCosts.map((document) => (
                  <ListItem key={document.id}>
                    <Text>{getCalculatedFileName(document)}</Text>
                  </ListItem>
                ))}
              </UnorderedList>
              <Text>
                {t(
                  "bulk.content.confirmIgnoreInvoicesWithTrackedCostsSecondary",
                  {
                    count: invoicesWithTrackedCosts.length,
                  }
                )}
              </Text>
            </Fragment>
          }
          onCloseComplete={handleClose}
          onConfirm={handleConfirm(confirmActionName)}
          open
          title={t("bulk.header.onIgnore")}
        />
      );
    }

    if (
      confirmActionName === "onIgnore" &&
      !includesDrawSummaryWithAdjustments
    ) {
      handleConfirm(confirmActionName)();
      return null;
    }

    if (
      confirmActionName === "onRemove" &&
      includesAgreementWithAdjustments &&
      !canCreateAdjustments
    ) {
      return (
        <Confirm
          confirmLabel="OK"
          content={
            <Fragment>
              <Text>
                {t("bulk.content.cannotDeleteWithAdjustment", {
                  count: agreementsWithAdjustments.length,
                })}
              </Text>
              <UnorderedList marginY={majorScale(2)}>
                {agreementsWithAdjustments.map((document) => (
                  <ListItem key={document.id}>
                    <Text>{getCalculatedFileName(document)}</Text>
                  </ListItem>
                ))}
              </UnorderedList>
              <Text>
                {t("bulk.content.cannotDeleteWithAdjustmentSecondary", {
                  count: agreementsWithAdjustments.length,
                })}
              </Text>
            </Fragment>
          }
          hasCancel={false}
          onCloseComplete={handleClose}
          open
          title={t("bulk.header.onRemove")}
        />
      );
    }

    if (
      confirmActionName === "onRemove" &&
      includesAgreement &&
      hasAgreementManagement
    ) {
      return (
        <AlsoDeleteAgreementModal
          documents={selectedDocuments}
          onClose={handleClose}
          onDelete={handleConfirm(confirmActionName)}
        />
      );
    }

    const count = selectedDocumentIds.length;
    const drawSummaryDeletionText =
      includesDrawSummaryWithAdjustments && confirmActionName === "onRemove"
        ? ` ${t("bulk.content.removeDrawSummaryAdjustments")}`
        : "";
    const agreementDeletionText =
      includesAgreementWithAdjustments && confirmActionName === "onRemove"
        ? ` ${t("bulk.content.removeAgreementAdjustments")}`
        : "";
    const confirmActionContent = t(`bulk.content.${confirmActionName}`, {
      count,
    });

    const content = `${confirmActionContent}${drawSummaryDeletionText}${agreementDeletionText}`;

    return (
      <Confirm
        content={content}
        header={t(`bulk.header.${confirmActionName}`)}
        onCloseComplete={handleClose}
        onConfirm={handleConfirm(confirmActionName)}
        open
      />
    );
  };

  const onApprove = (onComplete) => handleOpen("onApprove", onComplete);

  const onRemove = (onComplete) => handleOpen("onRemove", onComplete);

  const onIgnore = (onComplete) => handleOpen("onIgnore", onComplete);

  const onMarkBackup = (onComplete) =>
    frozenSubmit((selectedDocuments) =>
      markBackupMutation({
        variables: { documentIds: selectedDocuments.map(({ id }) => id) },
      }).then(onComplete)
    );

  const onAssignTo = (options) => assignToMutation(options);

  function onMoveToDraw({ documents, drawId, onComplete }) {
    const frozenDocuments = documents.filter((document) => {
      const { approvedCount } = aggregateDocumentApprovalsData(
        document,
        documentReviewers
      );
      return approvedCount > 0 || includes(frozenDrawIds, document?.draw?.id);
    });
    if (frozenDocuments.length > 0) {
      setIneligibleDocuments(frozenDocuments);
    } else {
      const documentIds = documents.map(({ id }) => id);
      moveToDrawMutation({ variables: { documentIds, drawId } }).then(
        onComplete
      );
    }
  }

  const mutationLoading =
    approveLoading ||
    assignLoading ||
    moveLoading ||
    markBackupLoading ||
    ignoreLoading ||
    removeLoading;

  return (
    <Fragment>
      {renderConfirm()}
      {children({
        hasAssignedToPermission,
        hasDownloadPermission,
        hasStampDocumentsPermission,
        mutationLoading,
        onAssignTo,
        onApprove,
        onDownload,
        onDownloadOriginal,
        onDownloadStamped,
        onIgnore,
        onMarkBackup,
        onMoveToDraw,
        onRemove,
      })}
      <Confirm
        open={!!ineligibleDocuments}
        content={getIneligibleDocumentsContent(
          ineligibleDocuments,
          frozenDrawIds,
          documentReviewers
        )}
        documents={ineligibleDocuments}
        header="Cannot Bulk Move Selected Documents"
        hasCancel={false}
        confirmLabel="OK"
        onCloseComplete={() => setIneligibleDocuments(null)}
      />
    </Fragment>
  );
};

export function DocumentActions({
  children,
  deleteRefetch,
  drawId,
  projectId,
  documentReviewers,
  frozenDrawIds,
}) {
  const { hasPermission, userId } = useContext(UserContext);
  const hasDownloadPermission = hasPermission(
    PERMISSION_ACTION.DOWNLOAD_DOCUMENT
  );
  const hasStampDocumentsPermission = hasPermission(
    PERMISSION_ACTION.STAMP_DOCUMENTS
  );
  const hasAssignedToPermission = hasPermission(
    PERMISSION_ACTION.ASSIGN_DOCUMENTS
  );
  const hasAgreementManagement = hasPermission(
    PERMISSION_ACTION.AGREEMENT_MANAGEMENT
  );
  const canCreateAdjustments = hasPermission(
    PERMISSION_ACTION.MAKE_PROJECT_BUDGET_ADJUSTMENTS
  );

  return (
    <Frozen projectId={projectId}>
      {({ frozenError, frozenSubmit, isFrozenError }) => {
        return (
          <DocumentActionsInner
            canCreateAdjustments={canCreateAdjustments}
            deleteRefetch={deleteRefetch}
            documentReviewers={documentReviewers}
            frozenError={frozenError}
            frozenSubmit={frozenSubmit}
            frozenDrawIds={frozenDrawIds}
            hasAgreementManagement={hasAgreementManagement}
            hasAssignedToPermission={hasAssignedToPermission}
            hasDownloadPermission={hasDownloadPermission}
            hasStampDocumentsPermission={hasStampDocumentsPermission}
            isFrozenError={isFrozenError}
            drawId={drawId}
            projectId={projectId}
            userId={userId}
          >
            {children}
          </DocumentActionsInner>
        );
      }}
    </Frozen>
  );
}

DocumentActions.propTypes = {
  drawId: PropTypes.string,
  projectId: PropTypes.string,
};
