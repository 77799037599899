import { useContext, useCallback } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import { ArrowRightIcon } from "evergreen-ui";
import { hover } from "glamor";
import {
  ConfigureButtons,
  Pane,
  Text,
  Shortener,
  EmptyCard,
} from "components/materials";
import { flatten, intersectionBy, max, uniq } from "lodash";
import { add } from "helpers/math";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { trackPortfolioAnalysis } from "./helpers";
import { BlankSlate } from "./BlankSlate";
import { PORTFOLIO_VENDORS_QUERY } from "./graphql";
import { CardHeading } from "./CardHeading";
import { CardLinkButton } from "./CardLinkButton";

export const PROJECTS_BY_VENDOR_CONFIGURATION_SETTINGS = {
  i: "projectsByVendor",
  x: 0,
  y: 6,
  w: 1,
  h: 1,
  disabled: false,
};

function VendorCount({
  activeProjectCount,
  maxNumProjects,
  name,
  navigateToProjects,
  theme,
}) {
  return (
    <Pane display="flex" marginBottom={majorScale(2)}>
      <Pane
        display="flex"
        alignItems="center"
        cursor="pointer"
        onClick={navigateToProjects}
        width={300}
        marginRight={majorScale(2)}
      >
        <Shortener
          color={theme.colors.linkBlue}
          className={hover({
            textDecoration: "underline",
          })}
          limit={20}
          size={200}
          text={name}
        />
        <ArrowRightIcon
          color={theme.colors.linkBlue}
          icon={ArrowRightIcon}
          appearance="minimal"
          marginLeft="auto"
        />
      </Pane>
      <Pane width="100%">
        <Pane
          paddingX={minorScale(2)}
          display="flex"
          alignItems="center"
          width={`${max([6, (activeProjectCount / maxNumProjects) * 100])}%`}
          height={30}
          background={theme.colors.barChartBlue}
        >
          <Pane marginLeft="auto">
            <Text color="white">{activeProjectCount}</Text>
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  );
}

export function ProjectsByVendor({
  isConfigurable,
  isDisabled,
  cards,
  setCards,
  name,
  organization,
  projects,
}) {
  const history = useHistory();
  const theme = useContext(ThemeContext);

  const useVendorsQuery = () =>
    useQuery(PORTFOLIO_VENDORS_QUERY, {
      variables: { organizationId: organization.id },
    });

  const useVendors = useCallback(useVendorsQuery, [organization.id]);

  const { data, loading } = useVendors();

  if (loading) {
    return (
      <EmptyCard
        name="Projects By Vendor"
        isConfigurable={isConfigurable}
        isDisabled={isDisabled}
        cardName={name}
        cards={cards}
        setCards={setCards}
      >
        <Text>Loading...</Text>
      </EmptyCard>
    );
  }

  const organizationId = organization.id;

  const filteredProjects = intersectionBy(
    data.organization.projects,
    projects,
    "id"
  );

  const vendors = flatten(filteredProjects.map((project) => project.vendors));

  const countForVendor = vendors.reduce(
    (acc, vendor) => ({
      ...acc,
      [vendor.id]: acc[vendor.id] ? add(acc[vendor.id], 1) : 1,
    }),
    {}
  );

  const uniqueVendors = uniq(vendors);
  const sortedVendors = [...uniqueVendors].sort(
    (vendorA, vendorB) =>
      countForVendor[vendorB.id] - countForVendor[vendorA.id]
  );

  const maxNumProjects =
    sortedVendors.length === 0 ? 0 : countForVendor[sortedVendors[0].id];

  const showBlankSlate = vendors.length === 0;

  return (
    <Pane height="100%" width="100%" padding={majorScale(2)}>
      <Pane
        display="flex"
        marginBottom={minorScale(3)}
        justifyContent="space-between"
        paddingBottom={majorScale(2)}
      >
        <CardHeading disabled={isDisabled} text="Projects By Vendor" />
        {isConfigurable && (
          <ConfigureButtons
            isDisabled={isDisabled}
            cards={cards}
            setCards={setCards}
            name={name}
          />
        )}
        {!isConfigurable && <CardLinkButton href="/organizations" />}
      </Pane>
      {showBlankSlate ? (
        <BlankSlate />
      ) : (
        <Pane height="85%" paddingTop={majorScale(2)}>
          <Pane height="100%" overflowY="scroll">
            {sortedVendors.map((vendor) => {
              const { id: vendorId } = vendor;
              const navigateToProjects = () => {
                trackPortfolioAnalysis(organizationId, "Go to Vendor");
                return history.push(
                  `/organizations/${vendorId}?referrerSelectedOrgId=${organizationId}`
                );
              };
              return (
                <VendorCount
                  activeProjectCount={countForVendor[vendor.id]}
                  key={vendor.id}
                  maxNumProjects={maxNumProjects}
                  name={vendor.name}
                  navigateToProjects={navigateToProjects}
                  theme={theme}
                />
              );
            })}
          </Pane>
        </Pane>
      )}
    </Pane>
  );
}
